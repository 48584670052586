import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { InfoSource } from './GetStartedStep.types';

type InfoSourceRadiosProps = {
  infoSource: InfoSource;
  setSelectedInfoSource: Dispatch<SetStateAction<InfoSource>>;
};

type InfoSourceRadioOption = {
  id: string;
  title: string;
  description: string;
  value: InfoSource;
};

const InfoSourceRadios = ({
  infoSource,
  setSelectedInfoSource,
}: InfoSourceRadiosProps) => {
  const { t } = useTranslation('better_order_experience');

  const options: InfoSourceRadioOption[] = [
    {
      id: 'candidate',
      title: t('candidateDetails.infoSourceRadios.candidate.title'),
      description: t('candidateDetails.infoSourceRadios.candidate.description'),
      value: 'CANDIDATE',
    },
    {
      id: 'myself',
      title: t('candidateDetails.infoSourceRadios.myself.title'),
      description: t('candidateDetails.infoSourceRadios.myself.description'),
      value: 'MYSELF',
    },
  ];

  return (
    <M.ChoiceCardGroup
      data-testid='info-source-radio-group'
      type='radio'
      defaultSelected={infoSource}
      onChange={useCallback(
        (value: InfoSource) => setSelectedInfoSource(value),
        [],
      )}
      columns={{ l: 2, m: 2, s: 2 }}
      width='50%'
    >
      {options.map(choice => {
        const { id, title, description, value } = choice;
        return (
          <M.ChoiceCard
            key={id}
            data-testid={`info-source-radio-group-${id}`}
            title={title}
            description={description}
            value={value}
          />
        );
      })}
    </M.ChoiceCardGroup>
  );
};

export default InfoSourceRadios;
