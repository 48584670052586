import { M } from '@dashboard-experience/mastodon';
import { useReportLoading } from 'containers/Report';
import {
  Change,
  Defer,
  Engage,
  NeedsFollowUp,
  PostActions,
  PreAA,
  CancelScreenings,
} from 'modules/adjudication/ui';
import { InformationModal } from 'modules/adjudication/ui/decisions';
import { useAdjudicationChangeAllowed } from 'modules/adjudication/utilities';
import React, { memo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

type MatchParams = {
  reportId: string;
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr; // Two columns: one for the icon, one for the buttons
  grid-template-rows: auto auto;
  column-gap: 15px;
  row-gap: 10px;

  .adjudication-information-modal {
    grid-row: span 2;
  }

  .primary-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const ReportLifeCycleActions = () => {
  const adjudicationChangeButtonsEnabled = useAdjudicationChangeAllowed();
  const isReportLoading = useReportLoading();

  const reportMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/reports/:reportId',
  );
  const reportId = reportMatch?.params?.reportId;

  if (!adjudicationChangeButtonsEnabled || !reportId) return null;
  if (isReportLoading) return <M.LoadingInline />;

  return (
    <Container data-testid='report-lifecycle-actions'>
      <InformationModal />
      <div className='primary-actions'>
        <CancelScreenings />
        <PostActions />
        <PreAA />
        <Engage />
        <Change />
      </div>
      <div className='secondary-actions'>
        <Defer />
        <NeedsFollowUp />
      </div>
    </Container>
  );
};

export default memo(ReportLifeCycleActions);
