import checkrAnalytics from 'components/analytics';
import {
  CurrentUser,
  GenericObject,
  Callback,
} from '@dashboard-experience/utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  ORDER_BGC_AMPLITUDE_FLAG_KEY,
  ORDER_REDESIGN_KEY,
  ORDER_BACKGROUND_CHECK_V2_AMPLITUDE,
} from 'Constants';

export const analytics = checkrAnalytics as any;
const noop = () => {};
export const useTrackEvent = () => {
  const useNewOrderBackgroundFlow =
    useFlag(ORDER_REDESIGN_KEY)?.variantKey === 'enabled';

  const originalFlowFlag =
    useFlag(ORDER_BGC_AMPLITUDE_FLAG_KEY)?.variantKey === 'on';
  const newFlowFlag =
    useFlag(ORDER_BACKGROUND_CHECK_V2_AMPLITUDE)?.variantKey === 'on';

  const packagesAmplitudeTrackingEnabled = useNewOrderBackgroundFlow
    ? newFlowFlag
    : originalFlowFlag;

  let trackEvent;
  if (packagesAmplitudeTrackingEnabled) {
    trackEvent = analytics.track as (
      user: CurrentUser,
      eventName:
        | ORDER_BACKGROUND_CHECK_EVENT_NAMES
        | ADD_CHECKS_EVENT_NAMES
        | PACKAGE_BUILDER_EVENT_NAMES
        | ALIASES
        | PACKAGE_EDITOR_EVENT_NAMES,
      eventProperties?:
        | ORDER_BACKGROUND_CHECK_EVENT_PROPERTIES
        | ADD_CHECKS_EVENT_PROPERTIES
        | PACKAGE_BUILDER_EVENT_PROPERTIES,
      userProperties?: GenericObject,
      callback?: Callback,
    ) => void;
  } else {
    trackEvent = noop;
  }

  return trackEvent;
};

export enum ORDER_BACKGROUND_CHECK_EVENT_NAMES {
  PAGE_VIEWED = 'Checkr Dashboard Order BGC Page Viewed',
  COUNTRY_SELECTED = 'Checkr Dashboard Order BGC Country Selected',
  STATE_SELECTED = 'Checkr Dashboard Order BGC State Selected',
  CITY_SELECTED = 'Checkr Dashboard Order BGC City Selected',
  PROGRAM_SELECTED = 'Checkr Dashboard Order BGC Program Selected',
  PACKAGE_SELECTED = 'Checkr Dashboard Order BGC Package Selected',
  WASHINGTON_CONSENT_SELECTED = 'Checkr Dashboard Order BGC Washington Consent Selected',
  PERMISSIBLE_PURPOSE_SELECTED = 'Checkr Dashboard Order BGC Permissible Purpose Selected',
  CUSTOMIZE_ADD_ONS_VIEWED = 'Checkr Dashboard Order BGC Customize Add-ons Viewed',
  REMOVE_ADD_ONS_PAGE_COMPLETED = 'Checkr Dashboard Order BGC Remove Add-ons Page Completed',
  ADD_ONS_PAGE_COMPLETED = 'Checkr Dashboard Order BGC Add-ons Page Completed',
  START_OVER_CLICKED = 'Checkr Dashboard Order BGC Start Over Clicked',
  CERTIFICATION_BUTTON_CLICKED = 'Checkr Dashboard Order BGC Certification Button Clicked',
  CANDIDATES_EMAIL_ENTERED = 'Checkr Dashboard Order BGC Candidates Email Entered',
  INVITATION_SENT = 'Checkr Dashboard Order BGC Invitation Sent',
  PAGE_LEFT = 'Checkr Dashboard Order BGC Page Left',
  LEARN_MORE_BUTTON_SELECTED = 'Checkr Dashboard Order BGC Learn More Button Selected',
  ERROR_VIEWED = 'Checkr Dashboard Order BGC V2 Error Viewed',
  // Get Started Page
  GET_STARTED_PAGE_COMPLETED = 'Checkr Dashboard Order BGC V2 Get Started Completed',
  GET_STARTED_PAGE_VIEWED = 'Checkr Dashboard Order BGC V2 Get Started Viewed',
  // Review and Submit Order Page
  REVIEW_AND_SUBMIT_ORDER_EDITED = 'Checkr Dashboard Order BGC V2 Review&Submit Order Edited',
  REVIEW_AND_SUBMIT_ORDER_COMPLETED = 'Checkr Dashboard Order BGC V2 Review&Submit Order Completed',
  ORDER_SUBMIT_COMPLETED = 'Checkr Dashboard Order BGC V2 Order Submitted Completed',
  CUSTOMIZE_WITH_ADDONS_COMPLETED = 'Checkr Dashboard Order BGC V2 Customize Add-ons Completed',
  SELECT_PACKAGE_PAGE_COMPLETED = 'Checkr Dashboard Order BGC V2 Select Package Completed',
  GO_BACK_CLICKED = 'Checkr Dashboard Order BGC V2 Go Back Selected',
  OBC_SELECT_PACKAGE_STEP_VIEWED = 'Checkr Dashboard Order BGC V2 Select Package Viewed',
}

export enum PACKAGE_BUILDER_EVENT_NAMES {
  PACKAGE_BUILDER_PACKAGE_PAGE_VIEWED = 'Check Dashboard Package Builder Package Page Viewed',
  PACKAGE_BUILDER_CREATE_NEW_PACKAGE_VIEWED = 'Checkr Dashboard Package Builder Create New Package Viewed',
  PACKAGE_BUILDER_GET_STARTED_COMPLETED = 'Checkr Dashboard Package Builder Get Started Completed',
  PACKAGE_BUILDER_SELECT_PACKAGE_VIEWED = 'Checkr Dashboard Package Builder Select a Package Viewed',
  PACKAGE_BUILDER_SELECT_PACKAGE_COMPLETED = 'Checkr Dashboard Package Builder Select a Package Completed',
  PACKAGE_BUILDER_CUSTOMIZE_VIEWED = 'Checkr Dashboard Package Builder Customize Add-ons Viewed',
  PACKAGE_BUILDER_CUSTOMIZE_COMPLETED = 'Checkr Dashboard Package Builder Customize Add-Ons Completed',
  PACKAGE_BUILDER_BACK_CLICKED = 'Checkr Dashboard Package Builder Back Button Clicked',
  PACKAGE_BUILDER_CANCEL_CLICKED = 'Checkr Dashboard Package Builder Cancel Button Clicked',
  PACKAGE_BUILDER_MODAL_COMPLETED = 'Checkr Dashboard Package Builder Cancel Progress Modal Completed',
  PACKAGE_BUILDER_EDIT_CLICKED = 'Checkr Dashboard Package Builder Edit Button Clicked',
  PACKAGE_BUILDER_PACKAGE_SAVED = 'Checkr Dashboard Package Builder Package Saved',
}

export enum PACKAGE_EDITOR_EVENT_NAMES {
  PACKAGE_EDITOR_PAGE_VIEWED = 'Checkr Dashboard Package Editor Viewed',
  PACKAGE_EDITOR_COMPLETED = 'Checkr Dashboard Package Editor Completed',
  PACKAGE_EDITOR_PACKAGE_SAVED = 'Checkr Dashboard Package Editor Package Saved',
  PACKAGE_EDITOR_CANCEL_CLICKED = 'Checkr Dashboard Package Editor Cancel Button Clicked',
  PACKAGE_EDITOR_MODAL_COMPLETED = 'Checkr Dashboard Package Editor Cancel Progress Modal Completed',
}

export enum ADD_CHECKS_EVENT_NAMES {
  ADD_CHECKS_SELECTED = 'Checkr Dashboard Report View Add Checks Selected',
  GET_STARTED_COMPLETED = 'Checkr Dashboard Report View Add Checks Get Started Completed',
  SELECT_PACKAGE_COMPLETED = 'Checkr Dashboard Report View Add Checks Select Package Completed',
  ADD_ADD_ONS_COMPLETED = 'Checkr Dashboard Report View Add Checks Add Add-on Completed',
  REVIEW_AND_SUBMIT_ORDER_COMPLETED = 'Checkr Dashboard Report View Add Checks Review And Submit Order Completed',
  REVIEW_AND_SUBMIT_INVITE_SENT = 'Checkr Dashboard Report View Add Checks Invite Sent',
  ORDER_SUBMITTED_COMPLETED = 'Checkr Dashboard Report View Add Checks Order Submitted Completed',
  BACK_BUTTON_CLICKED = 'Checkr Dashboard Report View Add Checks Back Button Clicked',
  CANCEL_OR_EXIT_CLICKED = 'Checkr Dashboard Report View Add Checks Cancel Or Exit Clicked',
}

export enum ALIASES {
  ALIAS_OFFERED = 'Checkr Dashboard Order BGC Alias Offered',
  ALIAS_TOOLTIP_CLICKED = 'Checkr Dashboard Order BGC Alias Tooltip Clicked',
  ALIAS_ADDED = 'Checkr Dashboard Order BGC Alias Add On Selected',
  ALIAS_REMOVED = 'Checkr Dashboard Order BGC Alias Add On Removed',
  ALIAS_NOT_ORDERED = 'Checkr Dashboard Order BGC Alias Not Ordered',
}

type TernaryAsText = 'Yes' | 'No';

export type EditableSection =
  | 'Payment profile'
  | 'Candidate'
  | 'Work location'
  | 'Selected Package'
  | 'Selected Add-on';

export type OrderCompleteModal = 'Go to Candidate Page' | 'Order New Check';

export type BasePackageTypes = 'Basic+' | 'Essential' | 'Professional';

type ExitTypeType = 'Cancel' | 'Keep Editing';

type ORDER_BACKGROUND_CHECK_EVENT_PROPERTIES = {
  Source?:
    | 'Order BGC Button Candidate Page'
    | 'Order BGC Tab'
    | 'Back Button'
    | 'Order new check';
  Country?: string;
  State?: string;
  City?: string;
  Program?: string;
  Package?: string;
  'Permissible Purpose'?: string;
  'Completion Type'?: 'Cancel' | 'Remove' | 'Exit' | 'Continue' | 'Skip';
  'Original Package'?: string;
  'Original Package Name'?: string;
  'Original Package Price'?: number | string;
  'Original Package Screenings'?: string;
  'Add-on Screenings'?: string;
  'Add-on Price'?: number | string;
  'Package Saved'?: TernaryAsText;
  'New Package Name'?: string;
  'International Pricing Page Clicked'?: TernaryAsText;
  'Help Center Clicked'?: TernaryAsText;
  'Number Of Candidate Email Entered'?: number | null;
  'Number Of Candidate Phone Entered'?: number | null;
  'Left Method'?: 'Back Button' | 'Left Tab' | 'Close Window';
  'Edit Section'?: EditableSection;
  'Certification Button Clicked'?: TernaryAsText;
  'Checkr Service Agreement Clicked'?: TernaryAsText;
  'Learn More Selected'?: TernaryAsText;
  'Order Completion Type'?: OrderCompleteModal;
  'Candidate Info Provider'?: number;
  'Package Selected'?: BasePackageTypes;
  'Search Used'?: TernaryAsText;
  'Number of Pages Viewed'?: number;
  'Package Name'?: string;
  'Page Name'?: string;
  'Candidate Email Changed'?: TernaryAsText;
  'Error Name'?: string;
  'Page Type'?: 'Account Setup' | 'Order' | 'Dashboard';
  'Total Order Price'?: number;
  'Alias Price'?: number | string;
  'I9 Page Type'?: string;
  'Aliases Enabled'?: string;
  'Order Type'?: string;
  'Manual Bulk Upload Type'?: string;
  'Recommended Package Selected'?: TernaryAsText;
  'Recommended Package Type'?: string;
  'I9 Page Source'?: string;
  'Is First Order'?: TernaryAsText;
  'Deep Link'?: TernaryAsText;
  'Base Packages Offered'?: string;
};

type AddChecksCompletionType =
  | 'Order New Check'
  | 'Go to Candidate Page'
  | 'Exit Mark';

type AddChecksExitType = 'Exit Mark' | 'Cancel Button';

export type ADD_CHECKS_EVENT_PROPERTIES = {
  'Package Selected'?: string;
  'Info Links Clicked'?: string;
  'Package Price'?: number;
  'Add-on Screenings'?: string;
  'Add-on Price'?: number;
  'Total Order Price'?: number;
  'Completion Type'?: AddChecksCompletionType;
  'Page Name'?: string;
  'Exit Type'?: AddChecksExitType;
};

type PACKAGE_BUILDER_EVENT_PROPERTIES = {
  'Work Location'?: string;
  'Info Links Clicked'?: string;
  'Add-Ons Screenings'?: string;
  'Page Name'?: string;
  'Exit Type'?: ExitTypeType;
  'Help Center Clicked'?: TernaryAsText;
};
