import { useCandidate } from 'providers/Candidate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReport } from 'containers/Report';
import NonActionableBanner from './non-actionable-banner';
import { useAdjudicationEvents } from '../../../../adjudication/ui/report/events/api';
import { AdjudicationEventTypes } from '../../../../adjudication/data';
import { Candidate, Report } from '../../../../../types';

const AdjudicationEngagedBanner = () => {
  const { t } = useTranslation();
  const candidate: Candidate = useCandidate();
  const report: Report = useReport();
  const { data: adjudicationEvents } = useAdjudicationEvents(report.id);

  const adjudicationEvent = adjudicationEvents?.find(
    event => event.type === AdjudicationEventTypes.ENGAGED,
  );

  // Only show this banner if we have an 'engaged' AdjudicationEvent
  if (!adjudicationEvent) {
    return null;
  }

  const d: Date = new Date(Date.parse(adjudicationEvent.time));
  const displayDate: string = d.toLocaleString('en-us', {
    // @ts-ignore
    dateStyle: 'medium',
  });

  let displayText: string = t('banners.adjudicationEngaged.default', {
    displayDate,
  });

  if (adjudicationEvent.user) {
    const adjudicationName =
      adjudicationEvent.user.full_name || adjudicationEvent.user.email;

    const candidateName = candidate.first_name;

    displayText = t('banners.adjudicationEngaged.hasUser', {
      adjudicationName,
      candidateName,
      displayDate,
    });
  }

  return <NonActionableBanner kind='info' title={displayText} />;
};

export default AdjudicationEngagedBanner;
