import React, { useMemo, ReactNode } from 'react';
import { CurrentUser } from '@dashboard-experience/utils';
import { LoadingScreen } from 'components';
import { useUser } from 'context/CurrentUser';
import { useGetData } from '../../components/Packages/hooks';
import AccountContext, { ContextType } from './AccountContext';

type Props = {
  children: ReactNode;
};

type ProviderValue = ContextType & {
  user: CurrentUser;
};

const Provider: React.FC<Props> = ({ children }) => {
  const { account, user } = useUser();

  const {
    geos = [],
    programs = [],
    node = [],
    payment_profiles,
    billing_entity_enabled,
    isLoading,
  } = useGetData({
    account,
  });

  const value = useMemo(
    () =>
      ({
        account,
        geos,
        nodes: node,
        programs,
        payment_profiles,
        billing_entity_enabled,
        user,
      } as ProviderValue),
    [
      account,
      geos,
      node,
      programs,
      payment_profiles,
      billing_entity_enabled,
      user,
    ],
  );

  if (isLoading) return <LoadingScreen />;

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export default Provider;
