import { M } from '@dashboard-experience/mastodon';
import { useCandidateRedesignPhaseThree } from 'modules/candidate/utilities';
import React from 'react';
import AdjudicationEngagedBanner from './adjudication-engaged-banner';
import InvitationExpiredBanner from './invitation-expired-banner';
import InvitationSentBanner from './invitation-sent-banner';
import ReportArchivedBanner from './report-archived-banner';
import ReportDisputedBanner from './report-disputed-banner';

const RedesignBanners = () => {
  const candidateRedesignPhaseThree = useCandidateRedesignPhaseThree();

  // None of these banners should be shown to people without the phase 3 flagr
  if (!candidateRedesignPhaseThree) {
    return null;
  }

  return (
    <M.Grid>
      <M.GridRow>
        <InvitationExpiredBanner />
        <InvitationSentBanner />
        <AdjudicationEngagedBanner />
        <ReportArchivedBanner />
        <ReportDisputedBanner />
        {/* Add in other banners here as needed */}
      </M.GridRow>
    </M.Grid>
  );
};

export default RedesignBanners;
