import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { HelpFilledIconWithTooltip } from 'components/Packages/OrderBackgroundCheck/SharedItems';
import SelectableAlias from './SelectableAlias';
import { AliasesEnabledType } from './types';

const Container = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const BoldTitle = styled.div`
  color: ${colors.uiTextPrimaryLight};
  font-weight: 700;
`;

const Percentage = styled.span`
  color: ${colors.uiAqua600};
`;

const Description = styled.div`
  color: ${colors.uiTextSecondaryLight};
  margin-top: 1rem;
`;

const RecommendedLabel = styled.div`
  background: ${colors.uiAqua100};
  border-radius: 0.25rem;
  border: 1px solid ${colors.uiAqua200};
  color: ${colors.uiTextPrimaryLight};
  display: inline-block;
  font-weight: 500;
  margin-top: 0.5rem;
  padding: 0.125rem 0.375rem;
`;

const AliasTooltip = (
  <>
    Aliases can increase the number of found records by completing additional
    criminal searches for any aliases.
    <br />
    An alias or AKA (also known as) is any past or alternative name. Some
    aliases, such as maiden names, are intentional, and others with misspellings
    or errors are accidental.
  </>
);

const AddAlias: React.FC<{
  onClick?: Function;
  price?: string;
  aliasesEnabled: string;
  onTooltipClick?: Function;
  aliasParam?: string | null;
}> = ({
  onClick = () => {},
  price = '0',
  aliasesEnabled = AliasesEnabledType.OFF,
  onTooltipClick = () => {},
  aliasParam,
}) => {
  const [selected, setSelected] = useState(
    (aliasesEnabled === AliasesEnabledType.ON ? true : false ?? false) ||
      aliasParam === 'on',
  );

  const handleSelect = useCallback(() => {
    setSelected(!selected);
    onClick();
  }, [onClick, selected]);

  useEffect(() => {
    document.body.classList.add('page-addons');

    return () => {
      document.body.classList.remove('page-addons');
    };
  }, []);

  return (
    <Container data-testid='add-alias-container'>
      <BoldTitle data-testid='alias-bold-title'>
        Find up to <Percentage>27&#37;</Percentage> more records with aliases
        <HelpFilledIconWithTooltip
          definition={AliasTooltip}
          align='bottom-right'
          onClick={onTooltipClick}
        />
      </BoldTitle>
      <RecommendedLabel>Recommended</RecommendedLabel>
      <Description>
        Automatically search for records with up to 4 names (nickname or maiden
        name), even if the candidate hasn’t shared them.
      </Description>
      <SelectableAlias
        handleSelect={handleSelect}
        selected={selected}
        price={price}
      />
    </Container>
  );
};

export default AddAlias;
