import React from 'react';
import {
  usePostAAAllowed,
  useDisplayCancelAdverseAction,
} from 'modules/adjudication/utilities';
import PostAA from 'modules/adjudication/ui/decision/adverse-action/post-adverse-action/button';
import { Cancel } from 'modules/adjudication/ui/decision/adverse-action/cancel';
import useGetDropdownItems from './use-get-dropdown-items';

const PostActions = () => {
  const postAdverseActionAllowed = usePostAAAllowed();
  const displayCancelAdverseAction = useDisplayCancelAdverseAction();
  const { items, modals, action } = useGetDropdownItems();

  let Component: React.ElementType = () => null;

  if (postAdverseActionAllowed) {
    Component = PostAA;
  } else if (displayCancelAdverseAction) {
    Component = Cancel;
  }

  return (
    <>
      <Component dropdownItems={items} action={action} />
      {modals}
    </>
  );
};

export default PostActions;
