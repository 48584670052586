import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { uniq } from 'lodash';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { SelectNode } from 'components/PaymentProfiles/PaymentProfileStyledComponents';
import SelectState from 'components/Packages/InvitationLink/DropdownSelections/SelectState';
import SelectCity from 'components/Packages/InvitationLink/DropdownSelections/SelectCity';
import { useUser } from 'context/CurrentUser';

interface ShowLinkModalProps {
  isOpen: boolean;
  onClose: () => void;
  packageName: string;
  applyFlowUrl: string | undefined;
}

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    &::before {
      content: '\\00D7';
    }
    aria-label {
      content: 'Close Modal';
    }
  }
`;

const ModalBody = styled.div`
  p {
    margin-bottom: 1rem;
  }
`;

const InlineNotificationSuccess = styled(M.InlineNotification)`
  margin-top: 1rem;
`;

const LocationFlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

const ShowLinkModal: React.FC<ShowLinkModalProps> = ({
  isOpen,
  onClose,
  packageName,
  applyFlowUrl,
}) => {
  const currentUser = useUser();
  const { account, geos } = currentUser;
  const { segmentation_enabled, hierarchy_present } = account;

  const countries: string[] = useMemo(
    () =>
      uniq(
        geos
          .filter((geo: any) => geo.country)
          .map((geo: any) => geo.country)
          .concat(['US']),
      ),
    [geos],
  );

  const isDomestic = countries.includes('US');
  const invitationLinkForHierarchyEnabled = segmentation_enabled && isDomestic;

  const [linkUrl, setLinkUrl] = useState(applyFlowUrl);
  const [isCopied, setIsCopied] = useState(false);
  const [selectedNode, setSelectedNode] = useState({
    custom_id: '',
  });
  const [selectedCity, setSelectedCity] = useState({ name: '', state: '' });
  const [selectedState, setSelectedState] = useState({
    abbreviation: '',
  });

  const handleNotificationClose = useCallback(() => {
    setIsCopied(false);
  }, []);

  const createQueryParam = (key: string, value: string) => {
    if (!value) return '';
    return `${key}=${encodeURIComponent(value)}`;
  };

  useEffect(() => {
    const nodeQueryParam = createQueryParam('node_id', selectedNode.custom_id);
    const stateQueryParam = createQueryParam(
      'wl_state',
      selectedState.abbreviation,
    );
    const cityQueryParam = createQueryParam('wl_city', selectedCity.name);

    const queryParams = [nodeQueryParam, stateQueryParam, cityQueryParam];
    const filteredQueryParams = queryParams.filter(param => param !== '');
    const joinedQueryParams = filteredQueryParams.join('&');

    setLinkUrl(
      filteredQueryParams.length
        ? `${applyFlowUrl}?${joinedQueryParams}`
        : applyFlowUrl,
    );
  }, [applyFlowUrl, selectedNode, selectedState, selectedCity]);

  useEffect(() => {
    if (hierarchy_present && !selectedNode.custom_id) {
      setSelectedCity({ name: '', state: '' });
      setSelectedState({ abbreviation: '' });
    }
  }, [selectedNode, hierarchy_present]);

  const nodeAndStateSelected =
    selectedNode.custom_id && selectedState.abbreviation;
  const stateSelected = selectedState.abbreviation;

  const shouldShowCopyField = () => {
    if (!segmentation_enabled) return true;
    if (segmentation_enabled && !hierarchy_present) return stateSelected;
    if (segmentation_enabled && hierarchy_present) return nodeAndStateSelected;
    return false;
  };

  const handleCopy = useCallback((element: HTMLElement) => {
    navigator.clipboard.writeText(element.innerText.replace(/&amp;/g, '&'));
    setIsCopied(true);
  }, []);

  return (
    <M.ComposedModal open={isOpen}>
      <M.ModalHeader closeModal={onClose}>
        <ModalHeader>
          <h2>Copy link</h2>
        </ModalHeader>
      </M.ModalHeader>
      <M.ModalBody>
        <ModalBody>
          <p>
            Use the link below to schedule a post-accident or reasonable
            suspicion test. Your monthly invoice will show only completed tests.
          </p>
          {invitationLinkForHierarchyEnabled && segmentation_enabled && (
            <LocationFlexContainer>
              {hierarchy_present && (
                <SelectNode
                  account={account}
                  selectedNode={selectedNode}
                  setSelectedNode={setSelectedNode}
                />
              )}
              <SelectState
                selectedState={selectedState}
                setSelectedState={setSelectedState}
              />
              <SelectCity
                state={selectedState.abbreviation}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                isStateSet={!!selectedState.abbreviation}
              />
            </LocationFlexContainer>
          )}
          {isCopied && (
            <InlineNotificationSuccess
              kind='success'
              title=''
              onCloseButtonClick={handleNotificationClose}
            >
              Link copied
            </InlineNotificationSuccess>
          )}
          <br />
          <div>{packageName}</div>
          {shouldShowCopyField() && (
            <div data-testid='copy-field-container'>
              <M.CopyField
                text={linkUrl}
                data-testid='copy-invitation-link'
                copy={handleCopy}
                style={{ maxWidth: '45rem' }}
              />
            </div>
          )}
          <br />
          <p>
            By clicking above, I certify that: (1) I have reviewed and approved
            of all documents that will be presented to the candidates; (2) I
            have a permissible purpose to request a consumer report on the
            candidates; and (3) my use of consumer reports will comply with all
            applicable laws.
          </p>
        </ModalBody>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button onClick={onClose}>Close</M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default ShowLinkModal;
