import React, {
  useState,
  useCallback,
  MouseEvent,
  SyntheticEvent,
} from 'react';
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/adjudication/locales';
import { useGetReport } from 'api/reports';
import { useTranslation } from 'react-i18next';
import {
  CANDIDATE_REPORT_EVENT_NAMES,
  scrollToTop,
  useTrackEvent,
} from 'utils';

import Guard from 'modules/adjudication/ui/engage/button/guard';
import EngageModal from 'modules/adjudication/ui/engage/flow/modal/modal';

const EngageButton = () => {
  const { report } = useGetReport();
  const trackEvent = useTrackEvent();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const text = t(`${namespace}:report.actions.engaged.action`);

  const handleOpen = useCallback(
    (e: MouseEvent<HTMLElement> | SyntheticEvent<Element>) => {
      e?.stopPropagation();
      setOpen(true);
      trackEvent(
        CANDIDATE_REPORT_EVENT_NAMES.CUSTOMER_SELECTED_CANDIDATE_ACTION,
        { Action: text },
      );
      // TODO: remove once customer is standalone app
      scrollToTop();
    },
    [setOpen, text, trackEvent],
  );

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  if (report.archived) return null;

  return (
    <Guard>
      <M.Button data-testid='engage-button' onClick={handleOpen}>
        {text}
      </M.Button>
      {open && <EngageModal open={open} hideModal={handleClose} />}
    </Guard>
  );
};

export default EngageButton;
