import { M, colors } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ReportStatuses } from '@dashboard-experience/utils';
import { useCancelInvitation } from 'api/invitations';
import ArchiveInvitationAction from 'components/Candidate/ArchiveInvitationAction';
import DeleteCandidateData from 'components/Report/CandidateInformation/DeleteCandidateData';
import { useUser } from 'context/CurrentUser';
import { ENABLE_ARCHIVE_REPORTS } from 'Flags';
import { useCandidate } from 'providers/Candidate';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { CANDIDATE_REPORT_EVENT_NAMES, useTrackEvent } from 'utils/analytics';

const SecondaryActionPanelHeading = styled(M.Container.Row)`
  height: 48px;
  display: flex;
  color: ${colors.uiGrey800};
  padding: 0 30px !important;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  justify-content: space-between;
  margin-top: 14px;
`;

const ArchivedInvitationBanner = styled.div`
  background: ${colors.uiGrey100};
  border-bottom: 1px solid ${colors.uiGrey200};
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 1rem;
  color: ${colors.uiGrey900};
  line-height: 1.25;
  display: flex;
  align-items: center;

  svg {
    vertical-align: bottom;
    margin-right: 0.75rem;
    color: ${colors.uiGrey900}c7;
  }
`;

const CanceledInvitation = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.25rem;

  .mastodon-status-icon {
    margin-right: 0.5rem;
  }
`;

const Icon = styled(M.Icon)`
  margin-right: 0.5rem;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2px;
  padding-bottom: 1rem;
  margin-left: 30px;

  > * {
    margin-left: 30px;
  }
`;

const SecondaryPanelInvitations: React.FC<{
  invitationId?: string;
}> = ({ invitationId }) => {
  const currentUser = useUser();
  const candidate = useCandidate();
  const { invitations = [] } = candidate;
  const invitation =
    invitations.find((item) => item.id === invitationId) || invitations[0];

  const { last_report_id } = candidate;

  const isReport = Boolean(last_report_id) && !invitationId;

  const trackEvent = useTrackEvent();

  const { call: cancelInvitation, result } = useCancelInvitation(
    invitation,
    candidate.id,
  );

  const archiveInvitationFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';

  const showArchivedInvitationBanner =
    archiveInvitationFlagEnabled && invitation?.archived;

  const [displayDeletionModal, setDisplayDeletionModal] =
    useState<boolean>(false);

  const showDeleteCandidateDataModal = useCallback(() => {
    if (currentUser) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DELETE_CANDIDATE_DATA);
    }

    setDisplayDeletionModal(true);
  }, [currentUser, trackEvent]);

  const closeDeletionModal = useCallback(
    (completeType) => {
      setDisplayDeletionModal(false);

      if (currentUser) {
        trackEvent(
          CANDIDATE_REPORT_EVENT_NAMES.REPORT_CANDIDATE_MODAL_COMPLETED,
          {
            'Complete Type': completeType,
          },
        );
      }
    },
    [currentUser, trackEvent],
  );

  if (isReport) {
    return null;
  }

  return (
    <M.Container
      data-testid='aa-secondary-panel-container'
      style={{ padding: '0' }}
    >
      {showArchivedInvitationBanner && (
        <ArchivedInvitationBanner>
          <M.Icon icon='Box' size={20} />
          This invitation is archived
        </ArchivedInvitationBanner>
      )}
      <SecondaryActionPanelHeading>
        <M.Container.Col className='flex'>
          <h5 className='mb-0'>Secondary Actions</h5>
        </M.Container.Col>
      </SecondaryActionPanelHeading>
      <M.Grid>
        <ActionButtonsContainer>
          {invitation?.status === ReportStatuses.PENDING && (
            <>
              <M.Button
                kind='secondary'
                onClick={cancelInvitation}
                data-testid='cancel-invitation-button'
              >
                <Icon icon='Close' size='16' />
                Cancel invitation
              </M.Button>
              {result.isLoading && (
                <M.LoadingInline description='Canceling...' />
              )}
              {result.isSuccess && (
                <CanceledInvitation>
                  <M.StatusIcon icon='clear' />
                  Invitation successfully canceled
                </CanceledInvitation>
              )}
            </>
          )}
          {archiveInvitationFlagEnabled && invitation && (
            <ArchiveInvitationAction invitation={invitation} />
          )}
          <div>
            <M.Button
              size='small'
              type='button'
              kind='tertiary'
              onClick={showDeleteCandidateDataModal}
              data-testid='delete-candidate-data-button'
              style={{
                paddingTop: '0px',
                paddingRight: '8px',
                paddingBottom: '0px',
                paddingLeft: '0px',
              }}
            >
              Delete candidate data
            </M.Button>
          </div>
        </ActionButtonsContainer>
        {displayDeletionModal && (
          <DeleteCandidateData
            showModal={displayDeletionModal}
            onClose={closeDeletionModal}
            candidate={candidate}
            data-testid='delete-candidate-data-modal'
          />
        )}
      </M.Grid>
    </M.Container>
  );
};

export default SecondaryPanelInvitations;
