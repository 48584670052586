import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import AdjudicationSplitActionButton, {
  DropdownItem,
} from '../../common/split-action-button';

type Props = {
  dropdownItems: DropdownItem[];
  action: () => void;
};

const PostAdverseActionButton: React.FC<Props> = ({
  dropdownItems,
  action,
}) => {
  const { t } = useTranslation();
  const label = t(`${namespace}:report.actions.cancel.action`);

  return (
    <AdjudicationSplitActionButton
      buttonLabel={label}
      buttonAction={action}
      dropdownItems={dropdownItems}
      data-testid='cancel-adverse-action-button'
    />
  );
};

export default PostAdverseActionButton;
