import React from 'react';
import { Geo } from '@dashboard-experience/utils';
import type { GetStartedT } from 'components/BetterOrderExperience';
import { Account } from 'types';
import { BillingEntity } from 'types/Billing';

export type ContextType = {
  account: Account;
  geos: Geo[];
  nodes: GetStartedT.Node[];
  programs: GetStartedT.Program[];
  payment_profiles: BillingEntity[];
  billing_entity_enabled: boolean;
};

export const AccountContext = React.createContext<ContextType>({
  account: {},
  geos: [],
  nodes: [],
  programs: [],
  payment_profiles: [],
  billing_entity_enabled: false,
});

export default AccountContext;
