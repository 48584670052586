import { M, colors } from '@dashboard-experience/mastodon';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { NAV_COLLAPSE_TRANSITION, NAV_SIZES } from '../constants';

const SIDE_MARGINS = '12px';
const VERTICAL_MARGINS = '8px';

const ICON_SIZE = '20px';

const TEXT_COLOR = colors.uiTextSecondaryLight;
const TEXT_HOVER_COLOR = colors.uiAqua600;
const TEXT_ACTIVE_COLOR = colors.uiAqua700;

const hideOverflow = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: hidden;
`;

export const ListElement = styled.li`
  #mastodon && {
    border: 1px solid transparent;
    border-radius: 4px;
    height: 36px;
    margin-bottom: ${VERTICAL_MARGINS};
    cursor: pointer;

    &:only-of-type {
      /* Make sure that any lone buttons (e.g. Collapse) have proper spacing above them as well */
      margin-top: ${VERTICAL_MARGINS};
    }

    display: flex;
    align-items: center;

    ${hideOverflow}

    &.email div.expanded {
      overflow: hidden;
      text-overflow: ellipsis;

      span.collapsed {
        display: none;
      }
    }

    font-family: 'National 2';

    &.active {
      background-color: ${colors.uiGrey0};
      border: 1px solid ${colors.uiGrey200};

      box-shadow: 0px 2px 12px -4px rgba(14, 21, 28, 0.08),
        0px 6px 20px -6px rgba(1, 73, 98, 0.06);
    }

    &:hover,
    &:focus-within {
      background-color: ${colors.uiGrey0}C6; //C6 = 78% opacity
      border: 1px solid ${colors.uiGrey100};
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${TEXT_COLOR};

  // Make the <a> fill the entire <li> so that the whole region is clickable:
  padding: ${VERTICAL_MARGINS} 0 ${VERTICAL_MARGINS} ${SIDE_MARGINS};
  width: 100%;

  // When expanded, add a way to truncate overflowing text:
  &.expanded {
    ${hideOverflow}
  }
`;

export const LinklessElement = styled.div`
  padding: ${VERTICAL_MARGINS} ${SIDE_MARGINS};
`;

export const TabName = styled.span`
  color: ${TEXT_COLOR};

  transition: opacity ${NAV_COLLAPSE_TRANSITION};

  vertical-align: middle;
  margin-left: ${SIDE_MARGINS};
  font-weight: 500;

  ${ListElement}:hover & ,
  ${ListElement}:focus-within & ,
  ${ListElement}.active & {
    color: ${TEXT_ACTIVE_COLOR};
  }

  &.collapsed {
    opacity: 0;
  }
`;

export const Svg = styled.svg`
  vertical-align: middle;

  path {
    fill: ${TEXT_COLOR};
  }

  ${ListElement}:hover & path ,
  ${ListElement}:focus-within & path ,
  ${ListElement}.active & path {
    fill: ${TEXT_ACTIVE_COLOR};
  }
`;

export const NavContainer = styled.div`
  background-color: ${colors.bgSecondaryLight};
  border-right: 1px solid ${colors.uiGrey200};
  z-index: 99; // This ensures that any elements in the nav (including the submenus) take precedence in getting shown

  position: sticky;
  top: 0;

  display: flex;
  flex-direction: column;
  height: 100vh;

  transition: width ${NAV_COLLAPSE_TRANSITION};

  &.expanded {
    width: ${NAV_SIZES.expanded};
  }
  &.collapsed {
    width: ${NAV_SIZES.collapsed};
  }
`;
const ItemSizing = css`
  margin-left: ${SIDE_MARGINS};
  transition: width ${NAV_COLLAPSE_TRANSITION};

  ${NavContainer}.expanded & {
    width: calc(${NAV_SIZES.expanded} - calc(${SIDE_MARGINS} * 2));
  }
  ${NavContainer}.collapsed & {
    width: calc(${ICON_SIZE} + calc(${SIDE_MARGINS} * 2));
  }
`;

export const UL = styled.ul`
  ${ItemSizing}
`;

export const Divider = styled.hr`
  #mastodon && {
    border-top: 1px solid ${colors.uiGrey200};
  }

  ${ItemSizing}
`;

export const LogoSection = styled.div`
  height: 56px;

  background-color: ${colors.uiNavy800};
  padding-left: 26px;
  padding-top: 16px;
  padding-bottom: 20px;
  margin-bottom: 32px;

  img {
    height: ${ICON_SIZE};
    display: block;
  }

  svg {
    height: ${ICON_SIZE};
    display: none;
    fill: ${colors.uiGrey0};
  }

  &.collapsed {
    img {
      display: none;
    }
    svg {
      display: block;
    }
  }
`;

export const LinksSection = styled.div`
  flex-grow: 1;
  overflow-y: auto; // Show the scroll for just the middle section if needed
  scrollbar-width: thin;
  scrollbar-gutter: stable;
`;

export const CollapseSection = styled.div`
  box-shadow: 0px -4px 4px -3px rgba(72, 82, 94, 0.1);
  background-color: ${colors.uiGrey100};
`;

export const Menu = styled(M.Menu)`
  ${NavContainer}.collapsed & span.mastodon-menu-label {
    opacity: 0;
    transition: opacity ${NAV_COLLAPSE_TRANSITION};
  }

  #mastodon && {
    width: 100%;

    span.cds--popover-container {
      width: 100%;

      button.mastodon-menu-button {
        width: 100%;
        justify-content: flex-start;
        border: 1px solid transparent;

        svg {
          // Prevent the icon here from shrinking
          flex-grow: 1;
          flex-shrink: 0;
        }

        span.mastodon-menu-label {
          font-weight: 500;
          color: ${TEXT_COLOR};

          ${hideOverflow}

          transition: opacity ${NAV_COLLAPSE_TRANSITION} 2s;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      &:hover,
      &:focus-within {
        button.mastodon-menu-button {
          background-color: ${colors.uiGrey0}C6; //C6 = 78% opacity
          border: 1px solid ${colors.uiGrey100};

          span.mastodon-menu-label {
            color: ${TEXT_HOVER_COLOR};
          }
        }
      }
    }
  }
`;

export const NavDropdown = styled(M.Dropdown)<{ navCollapsed: boolean }>`
  margin: ${VERTICAL_MARGINS} ${SIDE_MARGINS};

  #mastodon && {
    #select-mam-account {
      border: none;
      height: 36px;
      overflow: visible;
      position: relative;

      button {
        box-sizing: border-box;
        background-color: ${colors.uiGrey100};
        border-radius: 4px;
        border: 1px solid ${colors.uiGrey200};
        margin: 0;
        width: ${props => (props.navCollapsed ? '43px' : '240px')};
        transition: width ${NAV_COLLAPSE_TRANSITION};
        ${props => props.navCollapsed && 'padding: 0;'};
        padding-left: 0;

        span {
          font-weight: 500;
          color: ${TEXT_COLOR};
        }

        .cds--list-box__menu-icon {
          ${props => props.navCollapsed && 'display: none;'};
        }

        .cds--list-box__label {
          height: 100%;
          width: 100%;
        }

        &:focus {
          border: 1px solid ${colors.uiGrey200} !important;
          background-color: ${colors.bgPrimaryLight};

          svg {
            fill: ${colors.uiAqua700} !important;
          }
          span {
            color: ${colors.uiAqua700};
          }
        }
      }

      ul {
        box-shadow: 0px 2px 12px -4px rgba(14, 21, 28, 0.08),
          0px 6px 20px -6px rgba(1, 73, 98, 0.06);
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-gutter: stable;
        position: absolute;
        transform-origin: bottom center;
        transform: translateX(
          ${props => (props.navCollapsed ? '43px' : '240px')}
        );
        transition: transform 500ms ease-out;
        top: 0;
        border-radius: 8px;
        max-height: 250px;
        width: 260px;

        li {
          padding: 8px 16px 8px 12px;
          gap: 6px;
          border-radius: 4px 0px 0px 0px;
          font-weight: 400;
          display: flex;
          align-items: center;
          background-color: ${colors.bgPrimaryLight} !important;

          span {
            gap: 8px;
          }
        }

        li:focus,
        li:hover,
        li:focus-within {
          background-color: ${colors.bgPrimaryLight} !important;

          .cds--list-box__menu-item__option {
            color: ${colors.uiAqua700} !important;
          }
        }

        li[aria-selected='true'].cds--list-box__menu-item--active
          .cds--list-box__menu-item__option {
          color: ${colors.uiAqua700};

          svg {
            fill: ${colors.uiAqua700} !important;
          }
        }
      }
    }
  }
`;

export const NavSelectedItem = styled.div<{ navCollapsed: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    height: ${ICON_SIZE};
    width: ${ICON_SIZE};
    margin: 8px 12px;
    flex-shrink: 0;
  }

  span {
    opacity: ${props => (props.navCollapsed ? '0' : '1')};
    transition: opacity 0.4s ease-in-out;
  }

  &:focus {
    border: 1px solid ${colors.uiGrey200} !important;
    background-color: ${colors.bgPrimaryLight};

    svg {
      fill: ${colors.uiAqua700} !important;
    }
    span {
      color: ${colors.uiAqua700};
    }
  }
`;
