import { M } from '@dashboard-experience/mastodon';
import {
  getReportStatus,
  getStatusTypeForReport,
} from '@dashboard-experience/utils';
import { useGetReportExceptions, useReportsWithMultiMvr } from 'api/reports';
import { useReportEta } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import findKey from 'lodash/findKey';
import noop from 'lodash/noop';
import { useReportTabsIndices, useUpdateReportTab } from 'modules/report';
import { useCandidateExceptions, useReportTabs } from 'providers/Candidate';
import React, { useCallback, useEffect, useState } from 'react';
import { getScreeningsSummary } from 'utils/ReportUtils';
import { getSummaryEtaTitle } from './utils';

type Props = {
  reportId: string;
};

const scrollToElement = ({ id, el }: { id?: string; el?: HTMLElement }) => {
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' });
  } else if (id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
};

const ReportProgressSummary: React.FC<Props> = ({ reportId }) => {
  const user = useUser();
  const { report, isLoading: isReportLoading } = useReportsWithMultiMvr({
    reportId,
  });
  const { selectedIndex, customerView, setCustomerView } = useReportTabs();
  const [elementId, setElementId] = useState<string | null>(null);
  const tabIndices = useReportTabsIndices(report);
  const updateTab = useUpdateReportTab(report);
  const { exceptions = [] } = useGetReportExceptions(report?.id);
  const candidateExceptions = useCandidateExceptions() || [];
  const { reportPrediction } = useReportEta(report);

  const onClick = useCallback(
    (id: string, tab: string) => () => {
      const newTabIndex = findKey(tabIndices, val => val === tab);
      if (
        (newTabIndex && selectedIndex !== Number(newTabIndex)) ||
        !customerView
      ) {
        updateTab({ selectedIndex: Number(newTabIndex) });
        setElementId(id);
        setCustomerView(true);
      } else {
        scrollToElement({ id });
      }
    },
    [tabIndices, selectedIndex, updateTab, customerView],
  );

  useEffect(() => {
    if (elementId && customerView) {
      const observer = new MutationObserver((_mutations, obs) => {
        const element = document.getElementById(elementId);
        if (element) {
          scrollToElement({ el: element });
          // Stop observing after the element is found and scrolled to
          obs.disconnect();
          // Reset the elementId after scrolling
          setElementId(null);
        }
      });

      const container = document.getElementById(
        'report-body-screenings',
      ) as HTMLElement;

      // Observe then the DOM content changes for the report screenings
      observer.observe(container, {
        childList: true,
        subtree: true,
      });

      return () => observer.disconnect();
    }

    return noop;
  }, [elementId, customerView]);

  if (!reportId) return null;
  if (isReportLoading) return <M.LoadingInline />;

  const unresolvedExceptions = [...exceptions, ...candidateExceptions].filter(
    exception => !exception.resolved_at,
  );

  // @ts-ignore TODO: Update to consume Report type from Utils once is updated
  const statusType = getStatusTypeForReport(report, user);
  // @ts-ignore
  const status = getReportStatus(report, statusType);
  const screenings = getScreeningsSummary(
    report,
    user,
    onClick,
    unresolvedExceptions,
  );

  if (!screenings.length) return null;

  const secondaryTitle = getSummaryEtaTitle(
    status,
    unresolvedExceptions,
    reportPrediction,
  );

  return (
    <M.Grid>
      <M.ProgressSummary
        title='REPORT SUMMARY'
        status={status}
        screenings={screenings}
        id='report-progress-summary'
        secondaryTitle={secondaryTitle}
      />
    </M.Grid>
  );
};

export default ReportProgressSummary;
