import React, { useCallback, useContext, useState } from 'react';
import { I9_ORDERING } from 'Flags';
import {
  ADD_CHECKS_EVENT_NAMES,
  useTrackEvent as useTrackAddChecksEvent,
} from 'components/Packages/Amplitude/analytics';
import { useUser } from 'context/CurrentUser';
import { useFlag } from '@dashboard-experience/react-flagr';
import UIContext from 'context/UI';
import { useHistory } from 'react-router-dom';
import queryString from 'querystring';
import { updateParentWindowUrl } from 'utils';
import AddChecks from 'components/Packages/AddChecks';
import { useCandidate } from 'providers/Candidate';
import MenuItem from './menu-item';
import ActionsSubMenuItem from './actions-sub-menu-item';

const AddChecksRenderLogic = () => {
  const showI9Button = useFlag(I9_ORDERING)?.variantKey === 'on';
  const [addCheckModalOpen, setAddCheckModalOpen] = useState(false);
  const { contextId, isIframe } = useContext(UIContext);
  const candidate = useCandidate();
  const history = useHistory();

  const trackAddChecksEvent = useTrackAddChecksEvent();
  const currentUser = useUser();

  const openModal = useCallback(
    e => {
      trackAddChecksEvent(
        currentUser,
        ADD_CHECKS_EVENT_NAMES.ADD_CHECKS_SELECTED,
      );
      setAddCheckModalOpen(true);
    },
    [currentUser, trackAddChecksEvent],
  );

  const handleI9Click = useCallback(() => {
    const queryParams = { source: 'report' };
    const queryStringified = queryString.stringify(queryParams);
    const path = '/i-9';
    if (isIframe && contextId) {
      updateParentWindowUrl({
        contextId,
        path,
        reload: true,
      });
    } else {
      history.push({
        pathname: '/i-9',
        search: queryStringified,
      });
    }
  }, [contextId, history, isIframe]);

  const isInternationalCandidate = candidate?.geos
    ? candidate.geos?.filter(
        geo => geo.country !== 'US' && geo.country !== null,
      )?.length > 0
    : false;

  const isCandidateEmailEmpty = !candidate?.email?.length;

  if (isInternationalCandidate || isCandidateEmailEmpty) {
    return null;
  }
  return (
    <>
      {showI9Button ? (
        <ActionsSubMenuItem
          openModal={openModal}
          handleI9Click={handleI9Click}
        />
      ) : (
        <MenuItem openModal={openModal} />
      )}
      <AddChecks
        candidate={candidate}
        modalOpen={addCheckModalOpen}
        setModalOpen={setAddCheckModalOpen}
      />
    </>
  );
};

export default AddChecksRenderLogic;
