import React from 'react';
import { invert } from 'lodash';

import { SECONDS, HOURS, DAYS } from 'utils/TimeUtils';
import {
  findEnvVar,
  GenericObject,
  getParamFromUrl,
  localStorageFallback,
  sessionStorageFallback,
} from '@dashboard-experience/utils';

import { isInternationalCountry } from 'utils/Filters';
import { Impersonation } from 'utils';
import { PickerOptions } from 'filestack-js';

import * as Flags from './Flags';

export * from './Flags';

export const FLAGR_FLAG_KEYS = Object.values(Flags);

export const ENV = findEnvVar('ENV');
export const SENTRY_DSN = findEnvVar('SENTRY_DSN');
export const COMMIT_SHA = findEnvVar('COMMIT_SHA');
export const SENTRY_ENV = findEnvVar('ENV');
export const { NODE_ENV } = process.env;
export const PRODUCTION = NODE_ENV === 'production';
export const DEVELOPMENT = NODE_ENV === 'development';
export const STAGING = ENV === 'staging';
export const SANDBOX = ENV === 'sandbox';

// Disable write requests
export const READ_ONLY = findEnvVar('READ_ONLY') === 'true';

// Analytics Keys
export const AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY = findEnvVar(
  'AMPLITUDE_EXPERIMENT_DEPLOYMENT_KEY',
);
export const AMPLITUDE_KEY = findEnvVar('AMPLITUDE_KEY');
export const PENDO_KEY = findEnvVar('PENDO_KEY');

export const ACCOUNT_HIERARCHY_UI_MAX_NODES = parseInt(
  findEnvVar('ACCOUNT_HIERARCHY_UI_MAX_NODES') || '300',
  10,
);
export const ADJUDICATION_API_URL = findEnvVar('ADJUDICATION_API_URL');
export const AMS_API_BASE_URL = findEnvVar('AMS_API_BASE_URL');
export const APPLY_URL = findEnvVar('APPLY_URL');
export const REACT_APP_API_STATUS_PROXY_URL = findEnvVar(
  'API_STATUS_PROXY_URL',
);
export const CHECKR_API_BASE_URL = findEnvVar('CHECKR_API_BASE_URL');
export const CHECKR_API_EU_BASE_URL = findEnvVar('CHECKR_API_EU_BASE_URL');
export const CHECKR_BILLING_URL = findEnvVar('CHECKR_BILLING_URL');
export const PARTNER_API_BASE_URL = findEnvVar('PARTNER_API_BASE_URL');
export const SEARCH_API_BASE_URL = findEnvVar('SEARCH_API_BASE_URL');
export const SIGNUP_FLOW_BASE_URI = findEnvVar('SIGNUP_FLOW_BASE_URI');
export const ACCOUNT_SERVICE_BASE_URL = findEnvVar('ACCOUNTS_API_URL');
export const RECAPTCHA_SITE_KEY = findEnvVar('RECAPTCHA_SITE_KEY');

export const COMMUNICATOR_API_URL = findEnvVar('COMMUNICATOR_API_URL');
export const COMMUNICATOR_EU_API_URL = findEnvVar('COMMUNICATOR_EU_API_URL');

export const GH_DASHBOARD_URL = findEnvVar('GH_DASHBOARD_URL');

export const SAVED_SEARCH_API_BASE_URL = findEnvVar(
  'SAVED_SEARCH_API_BASE_URL',
);

export const CHECKR_CUSTOMER_SUPPORT_URL = 'https://help.checkr.com/hc/en-us';
export const CANDIDATE_REDESIGN_LEARN_MORE_URL =
  'https://updates.checkr.com/announcements/introducing-checkr-s-new-report-page';

export const I9_API_BASE_URL = findEnvVar('I9_API_BASE_URL');
export const I9_ALLOWED_USER_ROLES = [
  'admin',
  'internal_admin',
  'requester',
  'restricted_admin',
];

export const TRACKER_URL = findEnvVar('TRACKER_URL');
export const OAUTH_URL = findEnvVar('OAUTH_URL') || '';

// Auto-prefix dashboard url with MR number in staging when url param is present, and handle -internal dashboard
const dashboardEnvVar = findEnvVar('CHECKR_DASHBOARD_URL');
export const dashboardInternalUrl = findEnvVar('CHECKR_DASHBOARD_INTERNAL_URL');

const searchParams = new URLSearchParams(window.location.search);
const allowInternalParam = searchParams.get('allowInternal');

if (allowInternalParam !== null) {
  // set in session storage, to persist in future sessions if we refresh or go through re-auth (which will lose the param at load time)
  sessionStorageFallback.setItem('allowInternal', allowInternalParam);
}

export const ALLOW_INTERNAL =
  // this will always be parsed and put in sessionStorage ahead of time, so use it as source of truth
  sessionStorageFallback.getItem('allowInternal') === 'true';

const isInternalDashboard = searchParams.get('internalDashboard') === 'true';
const baseDashboardUrl = isInternalDashboard
  ? dashboardEnvVar?.replace('dashboard.', 'dashboard-internal.')
  : dashboardEnvVar;
const mr =
  searchParams.get('mr') || sessionStorageFallback.getItem('dashboard_mr');

if (mr) sessionStorageFallback.setItem('dashboard_mr', mr);

export const IMPERSONATING: Impersonation = JSON.parse(
  localStorageFallback.getItem('impersonating') || '{}',
);

const replaceWithMR = (url: string | undefined) => {
  return url && (STAGING || SANDBOX) && mr
    ? url.replace('https://dashboard', `https://mr-${mr}-dashboard`)
    : url;
};

export const WINDOW_CONTEXT = getParamFromUrl(window, 'context');
export const WINDOW_CONTEXT_ID = getParamFromUrl(window, 'contextId');
export const IS_ANGULAR_IFRAME =
  WINDOW_CONTEXT === 'iframe' && !!WINDOW_CONTEXT_ID;

export const INTERNAL_DASHBOARD_URL = replaceWithMR(dashboardInternalUrl);
export const CHECKR_DASHBOARD_URL = replaceWithMR(baseDashboardUrl);

export const CHECKR_MARKETING_URL = findEnvVar('CHECKR_MARKETING_URL');

export const brandingFilestackKey = findEnvVar('BRANDING_FILE_STACK_API_KEY');
export const ccFilestackKey = findEnvVar('CC_FILE_STACK_API_KEY');
export const bulkOrderFilestackKey = findEnvVar(
  'BULK_ORDER_CSV_UPLOAD_FILE_STACK_API_KEY',
);
export const EXPUNGEMENTS_API_URL = findEnvVar('EXPUNGEMENTS_API_URL');
export const individualizedAssessmentFilestackKey = findEnvVar(
  'INDIVIDUALIZED_ASSESSMENT_FILE_STACK_API_KEY',
);
export const oauthAssetsFilestackKey = findEnvVar(
  'OAUTH_ASSETS_FILE_STACK_API_KEY',
);
export const accountHierarchyFilestackKey = findEnvVar(
  'ACCOUNT_HIERARCHY_FILE_STACK_API_KEY',
);

export const FLAGR_URL = findEnvVar('FLAGR_URL');
export const STRIPE_KEY = findEnvVar('STRIPE_KEY');

export const IDENTITY_PROVIDER_AUDIENCE = findEnvVar(
  'IDENTITY_PROVIDER_AUDIENCE',
);
export const IDENTITY_PROVIDER_CLIENT_ID = findEnvVar(
  'IDENTITY_PROVIDER_CLIENT_ID',
);
export const IDENTITY_PROVIDER_DOMAIN = findEnvVar('IDENTITY_PROVIDER_DOMAIN');

export const IDENTITY_PROVIDER_URL = findEnvVar('IDENTITY_PROVIDER_URL');

export const FORCE_TOKEN_REFRESH_WINDOW_IN_SECONDS = parseInt(
  findEnvVar('FORCE_TOKEN_REFRESH_WINDOW_IN_SECONDS') || '300',
  10,
);

export const REALTIME_URL = findEnvVar('REALTIME_URL');

export const DEVELOPERS_URL = findEnvVar('DEVELOPERS_URL');
export const CHECKR_PAY_URL = findEnvVar('CHECKR_PAY_URL');

export const filestackOptions: PickerOptions = {
  accept: [
    // From https://github.com/filestack/filestack-js/blob/723be11e1c1b4c4b5e3ab03705521b947b5d730e/src/lib/utils/extensions.ts#L205C3-L234C24
    // Explicitly rejecting svg, means we have to allowlist all the ones we DO want
    // until we update filestack and can make use of `acceptFn` instead
    // 'image/aces', // : ['exr'],
    'image/apng', // : ['apng'],
    'image/bmp', // : ['bmp'],
    // 'image/cgm', // : ['cgm'],
    // 'image/dicom-rle', // : ['drle'],
    // 'image/emf', // : ['emf'],
    // 'image/fits', // : ['fits'],
    // 'image/g3fax', // : ['g3'],
    'image/gif', // : ['gif'],
    'image/heic', // : ['heic'],
    'image/heic-sequence', // : ['heics'],
    'image/heif', // : ['heif'],
    'image/heif-sequence', // : ['heifs'],
    // 'image/ief', // : ['ief'],
    // 'image/jls', // : ['jls'],
    // 'image/jp2', // : ['jp2', 'jpg2'],
    'image/jpeg', // : ['jpeg', 'jpg', 'jpe'],
    'image/jpg', // : ['jpeg', 'jpg', 'jpe'],
    // 'image/jpm', // : ['jpm'],
    // 'image/jpx', // : ['jpx', 'jpf'],
    // 'image/jxr', // : ['jxr'],
    // 'image/ktx', // : ['ktx'],
    'image/png', // : ['png'],
    // 'image/sgi', // : ['sgi'],
    // 'image/svg+xml', // : ['svg', 'svgz'],
    // 'image/t38', // : ['t38'],
    // 'image/tiff', // : ['tif', 'tiff'],
    // 'image/tiff-fx', // : ['tfx'],
    'image/webp', // : ['webp'],
    // 'image/wmf', // : ['wmf'],
  ],
  storeTo: { location: 'S3' },
  uploadInBackground: false,
  fromSources: ['local_file_system'],
};

export const adjudicationItems = [
  'All',
  'None',
  'Engaged',
  'Pre Adverse Action',
  'Post Adverse Action',
  'Manual',
];

export const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'Dashboard Export',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
};

export const geoItems = [
  'San Francisco',
  'Miami',
  'Los Angeles',
  'san francisco',
  'Griffin State',
  'Checkr Test',
  'Checkr Checkr Checkr',
  'Mesa',
  'Site 1',
  'paylocity-test',
  'Vegas',
  'Tower',
  'Checkr Labs Chicago',
  'Checkr Labs DC',
  'Georgia',
  'Seattle',
  'San Diego',
  'Scranton',
  'Tahoe',
  'sugar land',
];

export const continuousCheckPackages = [
  'continuous_check',
  'continuous_monitoring',
  'continuous_mvr',
];

export const reportFilters = [
  'clear',
  'consider',
  'pending',
  'on_hold',
  'dispute',
];

export const reportIncludes = [
  'account',
  'appointment',
  'arrest_search',
  'candidate',
  'candidate_stories',
  'county_civil_searches',
  'county_criminal_searches',
  'credit_report',
  'dispute_comments',
  'documents',
  'drug_screening',
  'drug_alcohol_clearinghouse',
  'education_verification',
  'employer',
  'employment_verification',
  'eviction_search',
  'facis_search',
  'federal_civil_search',
  'federal_district_civil_searches',
  'federal_criminal_search',
  'federal_district_criminal_searches',
  'geos',
  'healthcare_sanctions_searches',
  'identity_verification',
  'occupational_health_screening',
  'international_criminal_searches',
  'identity_document_verification',
  'motor_vehicle_report',
  'municipal_criminal_searches',
  'national_criminal_search',
  'personal_reference_verifications',
  'photo_verification',
  'professional_license_verification',
  'professional_license_verifications',
  'professional_reference_verifications',
  'program',
  'public_comments',
  'report_tasks',
  'school',
  'sex_offender_search',
  'ssn_trace',
  'state_criminal_searches',
  'pointer_state_criminal_searches',
  'terrorist_watchlist_search',
  'international_adverse_media_searches',
  'international_canada_bc_enhanced_criminal_search',
  'international_criminal_searches_v2',
  'international_education_verification',
  'international_employment_verification',
  'international_identity_document_validation',
  'international_global_watchlist_search',
  'international_motor_vehicle_reports',
  'international_right_to_work',
  'clearinghouse_search',
  'child_abuse_searches',
  'self_disclosure_screening',
  'skipped_screenings',
].join(',');

export const SCREENING_NAME_MAP = {
  county_civil_search: 'County civil search',
  county_criminal_search: 'County criminal search',
  clearinghouse_search: 'Clearinghouse search',
  credit_report: 'Credit report',
  CreditReport: 'Credit report',
  drug_screening: 'Drug screening',
  drug_screening_v2: 'Drug screening',
  drug_alcohol_clearinghouse: 'Drug and alcohol clearinghouse',
  DrugScreening: 'Drug screening',
  DrugScreeningV2: 'Drug screening',
  education_verification: 'Education verification',
  employment_verification: 'Employment verification',
  eviction_search: 'Eviction search',
  facis_search: 'FACIS search',
  FacisSearch: 'FACIS search',
  federal_civil_search: 'Federal civil search',
  federal_criminal_search: 'Federal criminal search',
  federal_district_civil_search: 'Federal civil search',
  federal_district_criminal_search: 'Federal district criminal search',
  FederalDistrictCivilSearch: 'Federal civil search',
  global_watchlist_search: 'Global watchlist search',
  healthcare_sanctions_search: 'Healthcare sanctions search',
  HealthcareSanctionsSearch: 'Healthcare sanctions search',
  identity_document_verification: 'Identity document verification',
  IdentityDocumentVerification: 'Identity document verification',
  international_adverse_media_search: 'International adverse media search',
  international_criminal_search: 'International criminal search',
  international_criminal_search_v2: 'International criminal search',
  international_education_verification: 'International education verification',
  international_motor_vehicle_report: 'International motor vehicle report',
  international_employment_verification:
    'International employment verification',
  international_global_watchlist_search:
    'International global watchlist search',
  international_canada_bc_enhanced_criminal_search:
    'Canada BC Enhanced Criminal Search',
  InternationalCriminalSearch: 'International criminal search',
  InternationalEducationVerification: 'International education verification',
  InternationalEmploymentVerification: 'International employment verification',
  InternationalMotorVehicleReport: 'International motor vehicle report',
  MatrixCountyCriminalSearch: 'County criminal search',
  MatrixFederalCriminalSearch: 'Federal criminal search',
  MatrixFederalDistrictCriminalSearch: 'Federal district criminal search',
  MatrixGlobalWatchlistSearch: 'Global watchlist search',
  MatrixInternationalCriminalSearch: 'International criminal search',
  MatrixInternationalGlobalWatchlistSearch:
    'International global watchlist search',
  MatrixNationalCriminalSearch: 'National criminal search',
  MatrixSexOffenderSearch: 'Sex offender search',
  MatrixStateCriminalSearch: 'State criminal search',
  motor_vehicle_report: 'Motor vehicle report',
  multi_mvr: 'Multi-MVR',
  MultiMvr: 'Multi-MVR',
  municipal_criminal_search: 'Municipal criminal search',
  national_criminal_search: 'National criminal search',
  occupational_health_screening: 'Occupational health screening',
  OccupationalHealthScreening: 'Occupational health screening',
  PersonalReferenceVerification: 'Personal reference verifications',
  photo_verification: 'Photo verification',
  PhotoVerification: 'Photo verification',
  pro_license_verification: 'Professional license verification',
  ProfessionalReferenceVerification: 'Professional reference verification',
  ProLicenseVerification: 'Professional license verification',
  reference_verification: 'Reference verification',
  sex_offender_search: 'Sex offender search',
  ssn_trace: 'SSN trace',
  state_criminal_search: 'State criminal search',
  StatefulCountyCivilSearch: 'County civil search',
  StatefulDrugScreening: 'Drug screening',
  StatefulEducationVerification: 'Education verification',
  StatefulEmploymentVerification: 'Employment verification',
  StatefulEvictionSearch: 'Eviction search',
  StatefulMotorVehicleReport: 'Motor vehicle report',
  StatefulSsnTrace: 'SSN trace',
  terrorist_watchlist_search: 'Global watchlist search',
};

export const HEALTH_SCREENINGS = [
  'drug_screening',
  'occupational_health_screening',
];

export const SCREENING_SUBTYPE_NAME_MAP = {
  current: 'Current',
  current_no_email: 'Current - Skip Email Verification',
  last3: 'Last 3 Employers',
  last3_no_email: 'Last 3 Employers - Skip Email Verification',
  '7years': 'Last 7 Years',
  '10years': 'Last 10 Years',
  '15years': 'Last 15 Years',
  '99years': 'Last 99 Years',
  '5panel': '5 Panel',
  '5panelhair': '5 Panel Hair',
  '5panelinstant': '5 Panel Instant',
  '10panel': '10 Panel',
  '10panelinstant': '10 Panel Instant',
  DS_10_PANEL: 'DS_10_PANEL',
  dot: 'DOT',
  standard: 'Standard',
  instant: 'Instant',
  // eslint-disable-next-line prettier/prettier
  1: '1M',
};

export const REMOVEABLE_URL_PARAMS = [
  'accessToken',
  'checkrCacheBust',
  'token',
  'mr',
  'internalDashboard',
  'allowInternal',
  'clearUser',
];

export const DATE_FORMAT = 'MM/DD/YYYY';
export const PER_PAGE = 25;
export const COOKIE_NAME = 'checkr_token';

export const TYPE_PAM = 'adjudication_matrices';
export const TYPE_PKG = 'packages';
export const TYPE_USER = 'users';
export const TYPE_BRAND = 'brands';
export const TYPE_MVR_RULE = 'mvr_rules';
export const TYPE_APP_INSTANCE_CUSTOMER = 'application_instances_as_customer';

// Only API authorized accounts can create these
export const API_KEY_TOKENS = {
  token: 'Secret',
  publishable_token: 'Publishable',
};

export const API_KEY_TYPES = invert(API_KEY_TOKENS);

export const API_KEY_INTERVALS: GenericObject = {
  'a few seconds': 5 * SECONDS,
  'an hour': 1 * HOURS,
  '2 hours': 2 * HOURS,
  '4 hours': 4 * HOURS,
  '8 hours': 8 * HOURS,
  '12 hours': 12 * HOURS,
  'a day': 1 * DAYS,
};

export const TOAST_TIMEOUT = 5 * SECONDS;

export const MAX_BULK_AA_IDS = 20;

export const MAX_BULK_INVITATIONS = 200;

export const CANDIDATE_INFO_FIELDS = [
  {
    fieldName: () => 'First name',
    fieldKey: 'first_name',
    column: 0,
    optional: false,
    simpleCheck: true,
    hasTemplate: false,
  },
  {
    fieldName: () => 'Middle name',
    fieldKey: 'middle_name',
    column: 0,
    optional: false,
    simpleCheck: true,
    hasTemplate: false,
  },
  {
    fieldName: () => 'Last name',
    fieldKey: 'last_name',
    column: 0,
    optional: false,
    simpleCheck: true,
    hasTemplate: false,
  },
  {
    fieldName: () => 'Date of birth',
    fieldKey: 'dob',
    column: 0,
    optional: false,
    simpleCheck: true,
    hasTemplate: true,
  },
  {
    fieldName: () => 'Social security #',
    fieldKey: 'ssn',
    column: 0,
    optional: true,
    simpleCheck: true,
    hasTemplate: true,
  },
  {
    fieldName: () => 'National ID #',
    fieldKey: 'national_id_number',
    column: 0,
    optional: true,
    simpleCheck: true,
    hasTemplate: false,
  },
  {
    fieldName: (candidate: GenericObject, currentUser: GenericObject) => {
      const { geos } = candidate;
      const {
        account: { segmentationEnabled },
      } = currentUser;
      const isPlural = geos.length > 1;
      const segmentationText = `${
        segmentationEnabled ? 'Compliance ' : ' '
      }Geo${isPlural ? 's' : ''}`;
      return segmentationText;
    },
    fieldKey: 'geos',
    column: 0,
    optional: true,
    simpleCheck: false,
    hasTemplate: true,
  },
  {
    fieldName: (candidate: any) => {
      return candidate.data_residency_location &&
        isInternationalCountry(candidate.data_residency_location)
        ? 'Postal code'
        : 'Zip code';
    },
    fieldKey: 'zipcode',
    column: 0,
    optional: false,
    simpleCheck: true,
    hasTemplate: false,
  },
  {
    fieldName: () => 'Status',
    fieldKey: 'on_hold',
    column: 0,
    optional: true,
    simpleCheck: true,
    hasTemplate: true,
  },
  {
    fieldName: () => 'Driver license #',
    fieldKey: 'driver_license_number',
    column: 1,
    optional: false,
    simpleCheck: true,
    hasTemplate: true,
  },
  {
    fieldName: () => 'Prior driver license #',
    fieldKey: 'previous_driver_license_number',
    column: 1,
    optional: false,
    simpleCheck: true,
    hasTemplate: true,
  },
  {
    fieldName: () => 'Email',
    fieldKey: 'email',
    column: 1,
    optional: false,
    simpleCheck: true,
    hasTemplate: true,
  },
  {
    fieldName: () => 'Phone',
    fieldKey: 'phone',
    column: 1,
    optional: false,
    simpleCheck: true,
    hasTemplate: false,
  },
  {
    fieldName: () => 'Custom ID',
    fieldKey: 'custom_id',
    column: 1,
    optional: false,
    simpleCheck: true,
    hasTemplate: true,
  },
  {
    fieldName: () => 'Created at',
    fieldKey: 'created_at',
    column: 1,
    optional: false,
    simpleCheck: true,
    hasTemplate: true,
  },
];

export const DEFAULT_USER_PREFERENCES = {
  timelineFilterItems: [
    {
      keys: [
        'report',
        'assessment',
        'adjudication',
        'candidate_story',
        'report_change_event',
      ],
      name: 'Report updates',
      show: true,
    },
    {
      keys: ['screening'],
      name: 'Screenings updates',
      show: true,
    },
    {
      keys: ['communicator'],
      name: 'Communications events',
      show: true,
    },
    {
      keys: ['user_action', 'report_task'],
      name: 'User actions',
      show: true,
    },
  ],
  report: {
    candidateInformation: {
      expanded: true,
    },
    mvrCustomFiltersDropdown: {
      expanded: true,
    },
  },
  candidate: {
    postHire: {
      expanded: true,
    },
  },
  searchColumns: undefined,
  customerView: true,
  leftNavCollapsed: false,
  betaStates: {},
} as { [key: string]: any };

export const DEFAULT_NOTIFICATION_STATE = {
  settings: {
    can_disable_notify_on_report_disputed: false,
    notify_on_adverse_actions_paused_default: false,
    notify_on_adverse_actions_paused_escalated: false,
    notify_on_candidate_stories_submitted_post_aa: false,
    notify_on_candidate_stories_submitted_pre_aa: false,
    notify_on_invitation_expired: false,
    notify_on_invitation_sent: false,
    notify_on_report_assessment_eligible: false,
    notify_on_report_assessment_escalated: false,
    notify_on_report_assessment_review: false,
    notify_on_report_canceled: false,
    notify_on_report_clear: false,
    notify_on_report_created: false,
    notify_on_report_disputed: false,
    notify_on_report_suspended: false,
    notify_report_assessment_changes: false,
  },
  groupToggleOn: {
    adverse_actions_paused: false,
    assessments: false,
    reports: false,
    candidates: false,
    candidate_stories: false,
  },
  groupToggleIndeterminate: {
    adverse_actions_paused: false,
    reports: false,
    candidates: false,
    candidate_stories: false,
    assessments: false,
  },
  groupConfig: {
    reports: {
      name: 'reports',
      label: 'All report updates',
      items: {
        notify_on_report_created: {
          name: 'notify_on_report_created',
          label: 'Report created',
        },
        notify_on_report_suspended: {
          name: 'notify_on_report_suspended',
          label: 'Report status: Suspended',
        },
        notify_on_report_canceled: {
          name: 'notify_on_report_canceled',
          label: 'Report status: Canceled',
        },
        notify_on_report_disputed: {
          name: 'notify_on_report_disputed',
          label: 'Report status: Dispute',
          disabled: true,
        },
      },
    },
    adverse_actions_paused: {
      name: 'adverse_actions_paused',
      label: 'All report adverse action pause notifications',
      items: {
        notify_on_adverse_actions_paused_default: {
          name: 'notify_on_adverse_actions_paused_default',
          label: 'Report adverse action paused (daily digest)',
        },
        notify_on_adverse_actions_paused_escalated: {
          name: 'notify_on_adverse_actions_paused_escalated',
          label: 'Urgent action required (daily digest)',
        },
      },
    },
    assessments: {
      name: 'assessments',
      label: 'All report assessment updates',
      items: {
        notify_on_report_clear: {
          name: 'notify_on_report_clear',
          label: (
            <>
              <strong>Eligible</strong> assessments when the report is{' '}
              <strong>Clear</strong>
            </>
          ),
        },
        notify_on_report_assessment_eligible: {
          name: 'notify_on_report_assessment_eligible',
          label: (
            <>
              <strong>Eligible</strong> assessments when the report is{' '}
              <strong>not Clear</strong>
            </>
          ),
        },
        notify_on_report_assessment_review: {
          name: 'notify_on_report_assessment_review',
          label: (
            <>
              <strong>Review</strong> assessments
            </>
          ),
        },
        notify_on_report_assessment_escalated: {
          name: 'notify_on_report_assessment_escalated',
          label: (
            <>
              <strong>Escalated</strong> assessments
            </>
          ),
        },
        notify_report_assessment_changes: {
          name: 'notify_report_assessment_changes',
          label: 'Report assessments - daily digest',
        },
      },
    },
    candidates: {
      name: 'candidates',
      label: 'All candidate updates',
      items: {
        notify_on_invitation_sent: {
          name: 'notify_on_invitation_sent',
          label: 'When an invitation is sent',
        },
        notify_on_invitation_expired: {
          name: 'notify_on_invitation_expired',
          label: 'When an invitation expires',
        },
      },
    },
    candidate_stories: {
      name: 'candidate_stories',
      label: 'All Candidate Stories',
      items: {
        notify_on_candidate_stories_submitted_pre_aa: {
          name: 'notify_on_candidate_stories_submitted_pre_aa',
          label: (
            <>
              Candidate stories submitted <strong>before</strong> pre-adverse
              action notice
            </>
          ),
        },
        notify_on_candidate_stories_submitted_post_aa: {
          name: 'notify_on_candidate_stories_submitted_post_aa',
          label: (
            <>
              Candidate stories submitted <strong>after</strong> pre-adverse
              action notice
            </>
          ),
        },
      },
    },
  },
  // TODO Remove after delivery the user preference improvements
  // and close the Feature Flag 'karcoxhv55kecwufn' https://flagr-ui.checkrhq.net/#/flags/929
  groupConfigOld: {
    reports: {
      name: 'reports',
      label: 'All report updates',
      items: {
        notify_on_report_created: {
          name: 'notify_on_report_created',
          label: 'Report created',
        },
        notify_on_report_clear: {
          name: 'notify_on_report_clear',
          label: 'Report cleared',
        },
        notify_on_report_suspended: {
          name: 'notify_on_report_suspended',
          label: 'Report suspended',
        },
        notify_on_report_canceled: {
          name: 'notify_on_report_canceled',
          label: 'Report canceled',
        },
        notify_on_report_disputed: {
          name: 'notify_on_report_disputed',
          label: 'Report disputed',
          disabled: true,
        },
      },
    },
    adverse_actions_paused: {
      name: 'adverse_actions_paused',
      label: 'All report adverse action pause notifications',
      items: {
        notify_on_adverse_actions_paused_default: {
          name: 'notify_on_adverse_actions_paused_default',
          label: 'Report adverse action paused (daily digest)',
        },
        notify_on_adverse_actions_paused_escalated: {
          name: 'notify_on_adverse_actions_paused_escalated',
          label: 'Urgent action required (daily digest)',
        },
      },
    },
    assessments: {
      name: 'assessments',
      label: 'All report assessment updates',
      items: {
        notify_on_report_assessment_eligible: {
          name: 'notify_on_report_assessment_eligible',
          label: 'Report assessment Eligible',
        },
        notify_on_report_assessment_review: {
          name: 'notify_on_report_assessment_review',
          label: 'Report assessment Review',
        },
        notify_on_report_assessment_escalated: {
          name: 'notify_on_report_assessment_escalated',
          label: 'Report assessment Escalated',
        },
        notify_report_assessment_changes: {
          name: 'notify_report_assessment_changes',
          label: 'Report assessments (daily digest)',
        },
      },
    },
    candidates: {
      name: 'candidates',
      label: 'All candidate updates',
      items: {
        notify_on_invitation_sent: {
          name: 'notify_on_invitation_sent',
          label: 'Candidate invitation sent',
        },
        notify_on_invitation_expired: {
          name: 'notify_on_invitation_expired',
          label: 'Candidate invitation expired',
        },
      },
    },
    candidate_stories: {
      name: 'candidate_stories',
      label: 'All Candidate Stories',
      items: {
        notify_on_candidate_stories_submitted_pre_aa: {
          name: 'notify_on_candidate_stories_submitted_pre_aa',
          label: 'Candidate Stories submitted before pre Adverse Action notice',
        },
        notify_on_candidate_stories_submitted_post_aa: {
          name: 'notify_on_candidate_stories_submitted_post_aa',
          label: 'Candidate Stories submitted after pre Adverse Action notice',
        },
      },
    },
  },
};

export const TIMELINE_DATE_FORMAT = 'MM/DD/YYYY hh:mmA';

export const EMPLOYMENT_SETTINGS_EDITABLE_HOURS = 72;

export const RECORD_FIELDS = [
  'case_number',
  'file_date',
  'court_of_record',
  'court_jurisdiction',
  'arresting_agency',
  'county',
  'state',
  'full_name',
  'dob',
  'yob',
];

export const CHARGE_FIELDS = {
  // attribute from api,  human readable for display
  charge: 'Charge',
  charge_type: 'Charge Type',
  arrest_date: 'Arrest Date',
  offense_date: 'Offense Date',
  charge_date: 'Charge Date',
  plaintiff: 'Plaintiff',
  defendant: 'Defendant',
  plea: 'Plea',
  court: 'Court',
  disposition: 'Disposition',
  disposition_date: 'Disposition Date',
  conviction_date: 'Conviction Date',
  probation_time: 'Sentenced To Probation Time', // changed due to litigation risk
  prison_time: 'Sentenced To Prison Time', // changed due to litigation risk
  jail_time: 'Sentenced To Jail Time', // changed due to litigation risk
  restitution: 'Sentenced To Restitution', // changed due to litigation risk
  sentence: 'Other Sentencing Info', // changed due ECRQ-90
  criminal: 'Charge Type Is Criminal', // changed due to ECRQ-309
  sentence_date: 'Sentence Date',
  probation_status: 'Probation Status',
  release_date: 'Release Date',
  deposition: 'Deposition',
  deposition_date: 'Deposition Date',
  next_court_date: 'Next Court Date',
};

export const DEGREES = {
  HighSchool: 'High School',
  GED: 'GED',
  Associate: 'Associate',
  AA: 'Associate of Arts (A.A.)',
  AS: 'Associate of Science (A.S.)',
  AAS: 'Associate of Applied Science (A.A.S.)',
  Bachelor: 'Bachelor',
  BA: 'Bachelor of Arts (B.A.)',
  BS: 'Bachelor of Science (B.S.)',
  Master: 'Master',
  MA: 'Master of Arts (M.A.)',
  MS: 'Master of Science (M.S.)',
  Doctor: 'Doctor',
  Ph: 'Doctor of Philosophy (Ph.D.)',
  MD: 'Medical Doctor (M.D.)',
  JD: 'Juris Doctorate (J.D.)',
  DDS: 'Doctor of Dental Surgery (D.D.S.)',
  Pharm: 'Doctor of Pharmacy (Pharm.D.)',
  degree: 'Other',
};

// this list must stay in sync with all doc types that derive from EducationProof in
// https://gitlab.checkrhq.net/platform/checkr/-/tree/master/app/screenings/education_verification/documents
export const EDUCATION_PROOF_DOC_TYPES = [
  'degree',
  'diploma',
  'education_certificate',
  'education_proof',
  'transcript',
];

// this list must stay in sync with all doc types that derive from EmploymentProof in
// https://gitlab.checkrhq.net/platform/checkr/-/tree/master/app/screenings/employment_verification/documents
export const EMPLOYMENT_PROOF_DOC_TYPES = [
  'employment_proof',
  'paystub',
  'tax_form1099',
  'tax_form_schedule_c',
  'tax_form_w2',
];

export const INTL_CRIM_SEARCH_DOC_TYPES = ['canada_police_form'];

export const STATIC_EXCEPTIONS = ['plvManualEntry'];

export const KNOWN_EXCEPTION_TYPES = [
  'id_documentation_requested',
  'dl_documentation_requested',
  'previous_dl_documentation_requested',
  'ssn_documentation_requested',
  'ssn_confirmation_requested',
  'suspended_courts_inaccessible',
  'courts_delayed_or_inaccessible',
  'cc_mvr_dl_confirmation',
  'international_documentation_requested',
  'canada_police_information_check_requested',
  'mvr_consent',
  'driving_experience',
  'employment_documentation_requested',
  'education_documentation_requested',
];

export const HIDDEN_EXCEPTION_TYPES = [
  'drug_screening_suspended',
  'occupational_health_screening_suspended',
];

export const DOCUMENT_UPLOAD_REJECTION_REASONS = {
  poor_image_quality: 'Poor image quality',
  insufficient_documentation: 'The document is insufficient / unrelated',
  insufficient_licensing_history:
    'The document does not meet licensing history requirements',
  domestic_license_required: 'International licenses are not accepted',
  name_mismatch: 'Name mismatch',
  unsupported_license_state:
    "Report driver's license state not supported or not enabled for account.",
  insufficient_age: 'Does not meet age requirement',
};

export const STATUS_ORDER = ['pending', 'consider', 'suspended', 'clear'];

export const IS_IN_IFRAME = window.self !== window.top;

export const STATUS_LABELS = {
  does_not_meet_criteria: 'does not meet criteria',
  meets_criteria: 'meets criteria',
  needs_review: 'needs review',
  eligible: 'eligible',
  escalated: 'escalated',
  review: 'review',
};

// Continuous Check
export const C_MVR = 'Continuous MVR';
export const C_CRIMINAL = 'Continuous crim';
export const C_FACIS = 'Continuous FACIS';
export const CONTINUOUS_CHECK = 'Continuous check';

export const C_MVR_PRODUCT = 'mvr';
export const C_CRIMINAL_PRODUCT = 'criminal';

export const CC_BULK_ACTION_CSV_TEMPLATE = findEnvVar(
  'CC_BULK_ACTION_CSV_TEMPLATE',
);
export const CC_BULK_ACTION_AH_CSV_TEMPLATE = findEnvVar(
  'CC_BULK_ACTION_AH_CSV_TEMPLATE',
);

// Bulk Order with SMS
export const CSV_UPLOAD_BULK_ORDER_WITH_SMS_CSV_TEMPLATE = findEnvVar(
  'CSV_UPLOAD_BULK_ORDER_WITH_SMS_CSV_TEMPLATE',
);

// AH Template
export const ACCOUNT_HIERARCHY_CSV_TEMPLATE = findEnvVar(
  'ACCOUNT_HIERARCHY_CSV_TEMPLATE',
);

// Analytics Page
export const ANALYTICS_TABS_CONFIG = [
  {
    title: 'Overview',
    id: 'cat_overview',
    lookerName: 'overview',
    url: '/analytics/overview',
  },
  {
    title: 'Criminal',
    id: 'cat_criminal',
    lookerName: 'criminal',
    url: '/analytics/criminal',
  },
  {
    title: 'MVR',
    id: 'cat_mvr',
    lookerName: 'mvr',
    url: '/analytics/mvr',
  },
  {
    title: 'Verifications',
    id: 'cat_verifications',
    lookerName: 'verifications',
    url: '/analytics/verifications',
  },
  {
    title: 'Continuous',
    id: 'cat_cc',
    lookerName: 'continuous',
    url: '/analytics/continuous',
  },
  {
    title: 'Adjudication',
    id: 'cat_adjudication',
    lookerName: 'adjudication',
    url: '/analytics/adjudication',
  },
];

export const DD_RUM_APPLICATION_ID = findEnvVar('DD_RUM_APPLICATION_ID');
export const DD_RUM_CLIENT_TOKEN = findEnvVar('DD_RUM_CLIENT_TOKEN');
export const DD_RUM_SITE = findEnvVar('DD_RUM_SITE');
export const USE_DD_RUM =
  !DEVELOPMENT && // <-- If you need DEV env data in datadog, feel free to temporarily comment this line. But generally we don't want it
  !navigator.webdriver && // Automation-related data just pollutes DDRUM data, don't upload it
  DD_RUM_CLIENT_TOKEN &&
  DD_RUM_APPLICATION_ID &&
  DD_RUM_SITE;

export const SUPPORTED_LOCALES = {
  de: '(DE)',
  en: '(EN)',
  en_xl: '(en_XL)',
  es: '(ES)',
  es_mx: '(es_MX)',
  fr: '(FR)',
  fr_ca: '(fr_CA)',
  unknown: '(??)',
};

export const CHECKR_DIRECT_WEBSITE_CLIENT_ID =
  findEnvVar('CHECKR_DIRECT_WEBSITE_CLIENT_ID') || 'checkrdirect';
export const CHECKR_DIRECT_B_CLIENT_ID =
  findEnvVar('CHECKR_DIRECT_B_CLIENT_ID') || 'checkrdirectb';
export const CHECKR_GOODHIRE_WEBSITE_CLIENT_ID =
  findEnvVar('CHECKR_GOODHIRE_WEBSITE_CLIENT_ID') || 'checkrgoodhire';

export const HELP_CENTER_URL = findEnvVar('HELP_CENTER_URL');

export const ATS_INTEGRATION_API_BASE_URL = findEnvVar(
  'ATS_INTEGRATION_API_BASE_URL',
);

export const CHECKR_DIRECT_PACKAGES = [
  'checkrdirect_basic_plus_criminal',
  'checkrdirect_complete_criminal',
  'checkrdirect_essential_criminal',
  'checkrdirect_professional_criminal',
  'checkrdirect_international_basic_plus',
  'checkrdirect_international_professional',
  'checkrdirect_international_professional_plus',
  'Basic+',
  'Complete',
  'Essential',
  'Professional',
];
