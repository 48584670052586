import { ReactPortal } from 'react';
import noop from 'lodash/noop';
import { DropdownItem } from 'modules/adjudication/ui/decision/common/split-action-button';
import {
  useCancelAction,
  usePauseAction,
  usePostAdverseAction,
} from 'modules/adjudication/ui/decision/adverse-action';

type Modal = ReactPortal | null;
type Item = DropdownItem & { modal: Modal };

const addItem = (item: Item, items: DropdownItem[], modals: Modal[]) => {
  const { title, subtitle, action, modal } = item;
  items.push({
    title,
    subtitle,
    action,
  });
  modals.push(modal);
};

const useGetDropdownItems = () => {
  const cancel = useCancelAction();
  const pause = usePauseAction();
  const post = usePostAdverseAction();

  const items = [] as DropdownItem[];
  const modals: Modal[] = [];
  let action: () => void = noop;

  if (post) {
    addItem(post, items, modals);
    action = post.action;
  }

  if (pause) {
    items.push({
      title: pause.title,
      subtitle: pause.subtitle,
      action: pause.action,
    });
    modals.push(pause.modal);
  }

  if (cancel) {
    addItem(cancel, items, modals);
    action = cancel.action;
  }

  return {
    items,
    modals,
    action,
  };
};

export default useGetDropdownItems;
