import {
  FIRST_TABS,
  HELP_TABS,
  SECOND_TABS,
} from 'components/DashboardWrapper/tabs';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LogoImage from 'components/DashboardWrapper/Logo';
import { useUser } from 'context/CurrentUser';
import { M } from '@dashboard-experience/mastodon';
import { CurrentUser } from '@dashboard-experience/utils';
import { multiUserAccountSwitch, MultiAccount } from 'utils/AuthUtils';
import { fetchUser } from 'actions';
import { useDispatch } from 'react-redux';
import NavElement from './nav-element';
import PredefinedNavElement from './predefined-nav-element';
import {
  Divider,
  LogoSection,
  CollapseSection,
  NavContainer,
  LinksSection,
  UL,
  Menu,
  ListElement,
} from './styles';
import { useNavCollapse } from '../utilities/hooks';
import NavElementWithSubmenu from './nav-element-with-submenu';
import LogoInitial from './logo-initial';
import { NavIcon } from './nav-icon';
import NavDropdownComponent from './nav-dropdown';

type Props = {
  setNavCollapse: Function;
};

type CurrentUserWithMultiAccounts = CurrentUser & {
  multi_accounts?: MultiAccount[];
  multi_account_enabled?: boolean;
};

type MultiAccountState = {
  multiAccounts: MultiAccount[] | [];
  selectedAccount: MultiAccount | undefined;
};

const NavigationContainer: React.FC<Props> = ({ setNavCollapse }) => {
  const { pathname } = useLocation();
  const user: CurrentUserWithMultiAccounts = useUser();
  const [multiAccountState, setMultiAccountState] = useState<MultiAccountState>(
    { multiAccounts: [], selectedAccount: undefined },
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const multiAccountEnabled =
      user?.multi_account_enabled &&
      user?.multi_accounts &&
      user?.multi_accounts.length > 0;
    if (multiAccountEnabled) {
      let userMultiAccounts = user.multi_accounts || [];
      const selectedAccount = userMultiAccounts.find(
        (account: MultiAccount) => account.account_id === user?.account?.id,
      );

      // Move the selected account to the front of the array
      // Create a new array without the selected account
      userMultiAccounts = userMultiAccounts.filter(
        account => account !== selectedAccount,
      );

      // Add the selected account to the beginning of the new array
      if (selectedAccount) {
        userMultiAccounts.unshift(selectedAccount);
      }

      setMultiAccountState({
        multiAccounts: userMultiAccounts,
        selectedAccount,
      });
    }
  }, [user?.account?.id, user?.multi_account_enabled, user?.multi_accounts]);

  const handleAccountSwitch = useCallback(
    async (selected: MultiAccount) => {
      await multiUserAccountSwitch(selected.account_id);
      dispatch(fetchUser());
    },
    [dispatch],
  );

  const { isCollapsed, isCollapseForced, savePreferCollapsed } =
    useNavCollapse();

  const onClickCollapse = useCallback(() => {
    savePreferCollapsed(!isCollapsed);
  }, [isCollapsed, savePreferCollapsed]);

  useEffect(() => {
    // Some state management has to get passed up to the wrapper for some CSS classes in the main page body
    setNavCollapse(isCollapsed);
  }, [isCollapsed, setNavCollapse]);

  const sizeClassName = isCollapsed ? 'collapsed' : 'expanded';

  const handleLogout = useCallback(() => {
    window.location.href = '/logout';
  }, []);

  return (
    <NavContainer className={sizeClassName}>
      <LogoSection className={sizeClassName}>
        {/* Swap between the full "Checkr" logo vs. the "C" initial. To avoid re-requesting the image from the server on each collapse/expand, both are technically always rendered, just shown/hidden with css */}
        <LogoImage /> <LogoInitial />
      </LogoSection>
      {multiAccountState.selectedAccount && (
        <>
          <NavDropdownComponent
            id='select-mam-account'
            onChange={handleAccountSwitch}
            defaultValue={multiAccountState.selectedAccount}
            items={multiAccountState.multiAccounts}
            navCollapsed={isCollapsed}
          />
          <Divider />
        </>
      )}
      <LinksSection>
        <UL>
          {/* The core pages of the Dashboard - Candidates, Assess, Account, etc. */}
          {Object.entries(FIRST_TABS).map(([titleKey, tab]) => {
            return (
              <PredefinedNavElement
                key={titleKey}
                titleKey={titleKey}
                tab={tab}
                currentPath={pathname}
                navCollapsed={isCollapsed}
              />
            );
          })}
        </UL>
        <Divider />
        <UL>
          {/* These are some secondary products like I9, Randomized testing, etc. */}
          {Object.entries(SECOND_TABS).map(([titleKey, tab]) => {
            return (
              <PredefinedNavElement
                key={titleKey}
                titleKey={titleKey}
                tab={tab}
                currentPath={pathname}
                navCollapsed={isCollapsed}
              />
            );
          })}
        </UL>
        <Divider />
        <UL>
          {/* Help has links to our help center, API docs, etc */}
          <NavElementWithSubmenu
            tabName='help'
            navCollapsed={isCollapsed}
            submenuTabs={HELP_TABS}
          />

          <ListElement>
            <Menu
              icon={<NavIcon tabName='mail' />}
              label={user?.email || 'Current User'}
              menuOffset={{
                // Offsets to make the menu open to the right of the nav item
                top: -40,
                left: isCollapsed ? 114 : 212,
              }}
              hideTooltip
            >
              <M.Menu.MenuItem itemText='Logout' onClick={handleLogout} />
            </Menu>
          </ListElement>
        </UL>
      </LinksSection>

      {/* Only show the Collapse/Expand button if the user is actually allowed to change it */}
      {!isCollapseForced && (
        <CollapseSection>
          <UL>
            <NavElement
              text={!isCollapsed ? 'Collapse' : 'Expand'}
              navCollapsed={isCollapsed}
              tabName={!isCollapsed ? 'collapse' : 'expand'}
              onClick={onClickCollapse}
            />
          </UL>
        </CollapseSection>
      )}
    </NavContainer>
  );
};

export default NavigationContainer;
