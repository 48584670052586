import { useLocation, useRouteMatch } from 'react-router-dom';
import { useFlag } from '@dashboard-experience/react-flagr';
import { CANDIDATE_REDESIGN_PHASE_THREE } from 'Flags';
import { useMemo } from 'react';
import { useCandidate } from 'providers/Candidate';

// eslint-disable-next-line import/prefer-default-export
export const useIsCandidateRoute = () => {
  const location = useLocation();
  // Regular expression to match /candidates/:id
  const candidateDetailRegex = /^\/candidates\/[^\/]+/;

  return candidateDetailRegex.test(location.pathname);
};

export const useCandidateRedesignPhaseThree = () => {
  return useFlag(CANDIDATE_REDESIGN_PHASE_THREE)?.variantKey === 'on';
};

type MatchParams = {
  candidateId: string;
  reportId: string;
  invitationId: string;
};

export const useGetInvitationIdFromRoute = () => {
  const invitationMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/invitations/:invitationId',
  );

  return invitationMatch?.params?.invitationId;
};

/**
 * Uses the route information to find the applicable Invitation for a Candidate
 * @returns the invitation object, or undefined if not found
 */
export const useInvitation = () => {
  const candidate = useCandidate();
  const { invitations = [] } = candidate;

  const candidateMatch = useRouteMatch({
    path: '/candidates/:candidateId',
    exact: true,
  });

  const invitationId = useGetInvitationIdFromRoute();

  const invitation = useMemo(() => {
    if (invitationId) {
      return invitations.find(invitation => invitation.id === invitationId);
    }
    if (candidateMatch) {
      return invitations[0];
    }
    return undefined;
  }, [candidateMatch, invitationId, invitations]);

  return invitation;
};
