/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import { fetchTimeline } from './actions';
import { filterTimeline, TimelineEvent } from '../../components/Timeline';

export type FetchTimelineResponse = {
  isLoading: boolean;
  isError: boolean;
  timeline: TimelineEvent[];
};

export const useFetchTimelines = (
  contextName: string,
  contextId: string,
): FetchTimelineResponse => {
  const key: string = `${contextName}/${contextId}`;

  const request = () => fetchTimeline(contextName, contextId);

  const { data, isLoading, isError } = useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!contextId,
  });

  const timeline = data ? filterTimeline(data as TimelineEvent[]) : [];

  return { isError, isLoading, timeline };
};
