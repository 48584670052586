import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'modules/adjudication/locales';
import { useReport } from 'containers/Report';
import { useAdverseActions } from 'modules/adjudication/api';
import AdjudicationSplitActionButton, {
  DropdownItem,
} from '../../common/split-action-button';

type Props = {
  dropdownItems: DropdownItem[];
  action: () => void;
};

const PostAdverseActionButton: React.FC<Props> = ({
  dropdownItems,
  action,
}) => {
  const report = useReport();
  const { t } = useTranslation();
  const { adverseAction } = useAdverseActions(report.id);
  const label = t(`${namespace}:report.actions.post_adverse.action`);

  if (!adverseAction) {
    return null;
  }

  return (
    <AdjudicationSplitActionButton
      buttonLabel={label}
      buttonAction={action}
      dropdownItems={dropdownItems}
      data-testid='post-adverse-action-button'
    />
  );
};

export default PostAdverseActionButton;
