import { useFlag } from '@dashboard-experience/react-flagr';
import { useGetReport } from 'api/reports';
import { useFetchTimelines } from 'api/timeline';
import { ENABLE_ARCHIVE_REPORTS } from 'Flags';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NonActionableBanner from './non-actionable-banner';

const ReportArchivedBanner = () => {
  const { t } = useTranslation();
  const { report } = useGetReport();
  const { timeline } = useFetchTimelines('reports', report?.id);
  const archiveReportFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';

  const archivedEvent = timeline?.find(
    event => event.event === 'report_archived',
  );

  const archivedDate = archivedEvent?.time
    ? moment(archivedEvent.time).format('ll')
    : null;

  const archivedBy = archivedEvent?.extra_info
    ? JSON.parse(archivedEvent.extra_info)?.customer_email
    : null;

  const showBanner =
    report.archived && archiveReportFlagEnabled && archivedDate && archivedBy;

  if (!showBanner) {
    return null;
  }

  return (
    <NonActionableBanner
      kind='info'
      title={t('banners.reportArchived.text', {
        archivedBy,
        archivedDate,
      })}
    />
  );
};

export default ReportArchivedBanner;
