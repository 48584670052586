import React, { useMemo } from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  AssessStatuses,
  ReportStatuses,
  getResultOrStatus,
} from '@dashboard-experience/utils';
import { ReportProps, Exception } from 'types';
import { SCREENING_TITLES } from 'utils';

const VerificationLink: React.FC<{ url: string }> = ({ url }) => (
  <M.Link href={url} target='_blank'>
    {url}
  </M.Link>
);

const SSNTrace: React.FC<ReportProps> = ({
  report,
  reportStatusType,
  reportExceptions,
}) => {
  const { ssn_trace } = report;

  const exceptions: Exception[] = useMemo(
    () => (reportExceptions?.exceptions ? reportExceptions.exceptions : []),
    [reportExceptions],
  );

  const allExceptionsResolved = useMemo(
    () =>
      (exceptions || []).every(
        (exception: Exception) => exception.resolved_at !== null,
      ),
    [exceptions],
  );

  const ssnConfirmationRequested = useMemo(
    () => exceptions?.find(e => e.type === 'ssn_confirmation_requested'),
    [exceptions],
  );

  const ssnDocumentationRequested = useMemo(
    () => exceptions?.find(e => e.type === 'ssn_documentation_requested'),
    [exceptions],
  );

  const idDocumentationRequested = useMemo(
    () => exceptions?.find(e => e.type === 'id_documentation_requested'),
    [exceptions],
  );

  const ssnConfirmationType =
    ssnConfirmationRequested || ssnDocumentationRequested;

  if (!ssn_trace) return null;

  const reportHasFlag =
    ssn_trace.fake_ssn ||
    ssn_trace.dob_mismatch ||
    ssn_trace.name_mismatch ||
    ssn_trace.no_data ||
    ssn_trace.thin_file ||
    ssn_trace.data_mismatch ||
    ssn_trace.invalid_issuance_year ||
    ssn_trace.death_index;

  const status = getResultOrStatus(ssn_trace);

  const showNotices =
    (reportHasFlag && status === ReportStatuses.SUSPENDED) ||
    ssn_trace.searched_without_ssn;

  const useScreeningStatusOverAssessment =
    ssn_trace?.assessment?.display?.decision === AssessStatuses.ELIGIBLE;

  return (
    <M.Screening
      title='SSN Trace'
      id={SCREENING_TITLES.ssn_trace.id}
      screening={ssn_trace}
      statusType={reportStatusType}
      useScreeningStatusOverAssessment={useScreeningStatusOverAssessment}
    >
      {showNotices && (
        <>
          {ssn_trace.searched_without_ssn && (
            <M.InlineNotification
              hideCloseButton
              kind='warning'
              title='SSN-less is enabled for this report'
            />
          )}
          {allExceptionsResolved && (
            <>
              {ssn_trace.dob_mismatch && (
                <M.InlineNotification
                  hideCloseButton
                  kind='warning'
                  title='Date of Birth Discrepancy'
                  subtitle='Provided date of birth does not match the information returned from the SSN Trace.'
                />
              )}
              {ssn_trace.name_mismatch && (
                <M.InlineNotification
                  hideCloseButton
                  kind='warning'
                  title='Name Discrepancy'
                  subtitle='Provided name does not match the information returned from the SSN Trace.'
                />
              )}
              {ssn_trace.no_data && (
                <M.InlineNotification
                  hideCloseButton
                  kind='warning'
                  title='No Data'
                  subtitle='No addresses were identified during the SSN Trace.'
                />
              )}
              {ssn_trace.fake_ssn && (
                <M.InlineNotification
                  hideCloseButton
                  kind='warning'
                  title='SSN Discrepancy'
                  subtitle='There is a discrepancy in the SSN provided by the candidate.'
                />
              )}
              {ssn_trace.data_mismatch && (
                <M.InlineNotification
                  hideCloseButton
                  kind='warning'
                  title='Data Discrepancy'
                  subtitle="Information returned from the SSN Trace does not match any of the candidate's provided information."
                />
              )}
              {ssn_trace.death_index && (
                <M.InlineNotification
                  hideCloseButton
                  kind='warning'
                  title='Unable to Proceed'
                  subtitle='We are unable to proceed with the report based on the SSN provided.'
                />
              )}
              {ssn_trace.invalid_issuance_year && (
                <M.InlineNotification
                  hideCloseButton
                  kind='warning'
                  title='Issuance Year Discrepancy'
                  subtitle='The issuance year returned from the SSN Trace precedes the provided year of birth.'
                />
              )}
            </>
          )}
          {!allExceptionsResolved && (
            <>
              {(ssn_trace.dob_mismatch ||
                ssn_trace.name_mismatch ||
                ssn_trace.no_data ||
                (ssn_trace.invalid_issuance_year && !ssnConfirmationType)) && (
                <M.ActionableNotification
                  inline
                  hideActionButton
                  hideCloseButton
                  kind='warning'
                  title='Need More Candidate Information'
                  subtitle='The candidate did not respond to our request to upload a copy of their photo identification.'
                >
                  {idDocumentationRequested?.verification_uri && (
                    <VerificationLink
                      url={idDocumentationRequested.verification_uri}
                    />
                  )}
                </M.ActionableNotification>
              )}
              {ssn_trace.fake_ssn && (
                <M.ActionableNotification
                  inline
                  hideActionButton
                  hideCloseButton
                  kind='warning'
                  title='SSN Discrepancy'
                  subtitle='The candidate did not respond to our request to upload a copy of their social security card.'
                >
                  {ssnDocumentationRequested?.verification_uri && (
                    <VerificationLink
                      url={ssnDocumentationRequested.verification_uri}
                    />
                  )}
                </M.ActionableNotification>
              )}
              {(ssn_trace.invalid_issuance_year ||
                ssn_trace.death_index ||
                ssn_trace.data_mismatch ||
                ssn_trace.thin_file) &&
                ssnConfirmationType && (
                  <M.ActionableNotification
                    inline
                    hideActionButton
                    hideCloseButton
                    kind='warning'
                    title='Unable to Confirm Candidate Information'
                    subtitle={
                      ssnConfirmationRequested
                        ? 'The candidate did not respond to our request to confirm their social security number.'
                        : 'The candidate did not respond to our request to upload a copy of their social security card.'
                    }
                  >
                    {ssnConfirmationType?.verification_uri && (
                      <VerificationLink
                        url={ssnConfirmationType.verification_uri}
                      />
                    )}
                  </M.ActionableNotification>
                )}
            </>
          )}
        </>
      )}
      {(status === ReportStatuses.CLEAR ||
        status === ReportStatuses.COMPLETE) &&
        ssn_trace.thin_file && (
          <M.InlineNotification
            hideCloseButton
            kind='warning'
            title='No Data'
            subtitle='No addresses were identified during the SSN Trace.'
          />
        )}
      <div>
        Information returned by this search is not a part of the consumer report
        and may not be used for FCRA purposes, including eligibility
        determinations under the FCRA.
      </div>
    </M.Screening>
  );
};

export default SSNTrace;
