import React from 'react';
import { useCandidateRedesignPhaseThree } from 'modules/candidate';
import ReportActions from './report-actions';
import ReportActionsV2 from './v2';

type Props = {
  isScrolled: boolean;
};

const Actions: React.FC<Props> = ({ isScrolled }) => {
  const candidateRedesignPhaseThree = useCandidateRedesignPhaseThree();

  return candidateRedesignPhaseThree ? (
    <ReportActionsV2 isScrolled={isScrolled} />
  ) : (
    <ReportActions isScrolled={isScrolled} />
  );
};

export default Actions;
