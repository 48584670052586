import React, { FC } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import { Account } from 'types';
import { useUser } from 'context/CurrentUser';

const GreyBox = styled.div`
  background-color: ${colors.brandSlate2};
  padding: 1rem;
  border-radius: 0.25rem;
  margin: 1rem 0;

  p {
    margin: 0 !important;
  }
`;

const AdditionalInfo = styled.div`
  margin-top: 1rem;

  ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin: 0.5rem 0;
  }
`;

interface Props {
  isOpen: boolean;
  handleModalToggle: () => void;
}

const ManualTestInstructions: FC<Props> = ({ isOpen, handleModalToggle }) => {
  const currentUser = useUser();
  const account = currentUser?.account as Account;

  return (
    <M.ComposedModal open={isOpen}>
      <M.ModalHeader closeModal={handleModalToggle}>
        <h2>Schedule a test by phone</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <h4>Contact eScreen</h4>
        <p>
          eScreen can guide you to an appropriate testing site or coordinate
          onsite collection, which might cost more. Call{' '}
          <strong>1-800-881-0722</strong> and provide the information below:
        </p>
        <GreyBox>
          <p>
            <strong>Account name:</strong> {account.name}
          </p>
          <p>
            <strong>eScreen account:</strong> {account.escreen_account_number}
          </p>
        </GreyBox>
        <AdditionalInfo>
          Also have this information available:
          <ul>
            <li>Reason for test (post-accident or reasonable suspicion)</li>
            <li>Test type</li>
            <li>Modality (DOT or nonDOT)</li>
            <li>Customer control form</li>
            <li>Reporter name</li>
            <li>Donor name, SSN/ID number, and phone</li>
          </ul>
        </AdditionalInfo>
      </M.ModalBody>
      <M.ModalFooter>
        <M.Button onClick={handleModalToggle}>Confirm</M.Button>
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default ManualTestInstructions;
