import { M, colors } from '@dashboard-experience/mastodon';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { useCandidate } from 'providers/Candidate';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Report } from 'types';

import { useFlag } from '@dashboard-experience/react-flagr';
import DeleteCandidateData from 'components/Report/CandidateInformation/DeleteCandidateData';
import ArchiveReportAction from 'components/Report/ReportInformation/ArchivedReport/ArchiveReportAction';
import DownloadPdfButton from 'components/Report/ReportInformation/PdfDownload/PdfDownload';
import { ENABLE_ARCHIVE_REPORTS } from 'Flags';
import { CANDIDATE_REPORT_EVENT_NAMES, useTrackEvent } from 'utils/analytics';

const SecondaryActionPanelHeading = styled(M.Container.Row)`
  height: 48px;
  display: flex;
  color: ${colors.uiGrey800};
  padding: 0 30px !important;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  .flex {
    padding-top: 1.5rem !important;
    display: flex;
    align-items: self-start;
  }
`;

const ArchivedReportBanner = styled.div`
  background: ${colors.uiGrey100};
  border-bottom: 1px solid ${colors.uiGrey200};
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 1rem;
  color: ${colors.uiGrey900};
  line-height: 1.25;
  display: flex;
  align-items: center;

  svg {
    vertical-align: bottom;
    margin-right: 0.75rem;
    color: ${colors.uiGrey900}c7;
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  padding-bottom: 1rem;

  > * {
    margin-left: 30px;
  }

  #pdf-downloads {
    margin-left: 0;
  }
`;

const SecondaryPanelReports: React.FC<{ report: Report }> = ({ report }) => {
  const currentUser = useUser();
  const candidate = useCandidate();
  const trackEvent = useTrackEvent();

  const { last_report_id } = candidate;

  const isReport = Boolean(last_report_id);

  const [displayDeletionModal, setDisplayDeletionModal] =
    useState<boolean>(false);

  const showDeleteCandidateDataModal = useCallback(() => {
    if (currentUser) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DELETE_CANDIDATE_DATA);
    }

    setDisplayDeletionModal(true);
  }, [currentUser, trackEvent]);

  const closeDeletionModal = useCallback(
    (completeType) => {
      setDisplayDeletionModal(false);

      if (currentUser) {
        trackEvent(
          CANDIDATE_REPORT_EVENT_NAMES.REPORT_CANDIDATE_MODAL_COMPLETED,
          {
            'Complete Type': completeType,
          },
        );
      }
    },
    [currentUser, trackEvent],
  );

  const archiveReportFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';

  const showArchivedReportBanner = archiveReportFlagEnabled && report.archived;

  if (!isReport) {
    return null;
  }

  return (
    <M.Container
      data-testid='aa-secondary-panel-container'
      style={{ padding: '0' }}
    >
      <M.Grid>
        {showArchivedReportBanner && (
          <ArchivedReportBanner>
            <M.Icon icon='Box' size={20} />
            This report is archived
          </ArchivedReportBanner>
        )}
        <SecondaryActionPanelHeading>
          <M.Container.Col className='flex'>
            <h5 className='mb-0'>Secondary Actions</h5>
          </M.Container.Col>
        </SecondaryActionPanelHeading>
        <ActionButtonsContainer>
          {!archiveReportFlagEnabled &&
            (hasPermission(currentUser, 'read_reports') ||
              hasPermission(currentUser, 'download_health_pdf')) && (
              // @ts-ignore TODO: Remove this comment once Report type is updated in utils and is ready to be consumed
              <DownloadPdfButton currentUser={currentUser} report={report} />
            )}

          {archiveReportFlagEnabled && (
            <>
              {(hasPermission(currentUser, 'read_reports') ||
                hasPermission(currentUser, 'download_health_pdf')) && (
                <DownloadPdfButton
                  currentUser={currentUser}
                  // @ts-ignore TODO: Remove this comment once Report type is updated in utils and is ready to be consumed
                  report={report}
                />
              )}

              <ArchiveReportAction report={report} />
            </>
          )}

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <M.Button
              size='small'
              type='button'
              kind='tertiary'
              onClick={showDeleteCandidateDataModal}
              data-testid='delete-candidate-data-button'
              style={{
                paddingTop: '0px',
                paddingRight: '8px',
                paddingBottom: '0px',
                paddingLeft: '0px',
              }}
            >
              Delete candidate data
            </M.Button>
          </div>
        </ActionButtonsContainer>

        {displayDeletionModal && (
          <DeleteCandidateData
            showModal={displayDeletionModal}
            onClose={closeDeletionModal}
            candidate={candidate}
            data-testid='delete-candidate-data-modal'
          />
        )}
      </M.Grid>
    </M.Container>
  );
};

export default SecondaryPanelReports;
