import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import AddScreeningsAction from '../shared/AddScreeningsAction';
import AddonScreenings from '../enums/AddonScreenings';
import ScreeningType from '../enums/ScreeningType';
import { AdditionalProperties } from '../interfaces/AdditionalProperties';

const AddonScreening = styled.div`
  display: flex;
  padding: 16px;
  max-width: 232px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.1);

  @media all and (max-width: 1250px) {
    max-width: 100%;
  }

  @media all and (max-width: 1200px) {
    padding: 12px;
    max-width: 232px;
  }

  @media all and (max-width: 1000px) {
    max-width: 100%;
  }
`;

const ScreeningNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NameAndIcon = styled.div`
  display: flex;
`;

const ScreeningName = styled.div`
  color: ${colors.bgPrimaryDark};
  padding-top: 0.175rem;
  font-size: 14px;
  font-weight: 700;
  line-height: 100%; /* 14px */
  overflow-wrap: break-word;
  @media all and (max-width: 1350px) {
    font-size: 12px;
  }
`;

const ScreeningDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  @media all and (max-width: 1200px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
`;

const ScreeningPrice = styled.div`
  color: ${colors.brandNavy3};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-right: 8px;
`;

const Tooltip = styled(M.TooltipDefinition)`
  border-bottom: none !important;

  .cds--definition-tooltip {
    padding: 1rem;
    max-width: 18rem;
  }
`;

const HelpIcon = styled(M.Icon)`
  margin-bottom: -3px;
  margin-left: 2px;
`;

type AddonScreeningCardProps = {
  screeningName: AddonScreenings;
  screeningType: ScreeningType;
  screeningPrice: string;
  actionProps: {
    onAddClick: Function;
    onRemoveClick: Function;
    addedScreenings: string[];
    disabledScreenings: string[];
    additionalProperties: AdditionalProperties;
  };
};

const getTooltipDefinition = (screening: AddonScreenings) => {
  switch (screening) {
    case AddonScreenings.MotorVehicleRecord:
      return 'Search a state’s DMV or similar entity, confirm driver’s license, and identify driving incidents.';
    case AddonScreenings.FederalCriminalSearch:
      return 'Search for federal felony and midemeanor offenses including crimes that cross state lines';
    case AddonScreenings.EmploymentVerification:
      return 'Confirm the candidate’s employment history for the last 7 years to understand their experience and qualifications.';
    case AddonScreenings.EducationVerification:
      return 'Verifies the highest degree obtained from high school or equivalent through doctorates';
    default:
      return '';
  }
};

const AddonScreeningCard: React.FC<AddonScreeningCardProps> = ({
  screeningName,
  screeningType,
  screeningPrice,
  actionProps,
}) => {
  return (
    <AddonScreening>
      <ScreeningNameContainer>
        <NameAndIcon>
          <ScreeningName data-testid={`${screeningType}-name`}>
            {screeningName}
          </ScreeningName>

          <Tooltip
            openOnHover
            align='bottom'
            definition={getTooltipDefinition(screeningName)}
          >
            <HelpIcon icon='Information' />
          </Tooltip>
        </NameAndIcon>
      </ScreeningNameContainer>
      <ScreeningDataContainer>
        <ScreeningPrice>{screeningPrice}</ScreeningPrice>
        <AddScreeningsAction
          screeningType={screeningType}
          {...actionProps}
          isPopularAddon
        />
      </ScreeningDataContainer>
    </AddonScreening>
  );
};

export default AddonScreeningCard;
