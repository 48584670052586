/* eslint-disable react/jsx-no-bind */
import React, {
  RefObject,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { IMaskMixin } from 'react-imask';
import { useLocation } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from 'Constants';
import { updateSessionStorage } from 'components/Signup/utils/sessionStorage';
import useGetUser from 'components/Signup/hooks/useGetUser';
import {
  useTrackEvent,
  SIGNUP_EVENT_NAMES,
  FOX_SIGNUP_EVENT_NAMES,
} from 'utils/analytics';
import { stateGeos } from 'components/Signup/constants/geos';

import Accordion from '../../Accordion';
import {
  Bold,
  CaptchaPolicy,
  CheckboxRow,
  CheckboxStyled,
  Container,
  FlexColumn,
  FormRow,
  FormSection,
  HiddenReCAPTCHA,
  Input,
  RadioButton,
  RadioButtonSubtitle,
  RadioButtonTitle,
  Section,
  SectionDescription,
  TypeaheadStyled,
  TooltipIcon,
} from './styles';
import schema from './schema';
import ComplianceStateAndCitySelector from './ComplianceStateAndCitySelector';
import useSubmitWithRecaptcha from '../../../hooks/useSubmitWithRecaptcha';
import useInitialValues from './useInitialValues';
import getFormStatus from './getFormStatus';
import PasswordInput from './PasswordInput';
import ChoiceCardGroup from './ChoiceCardGroup';

type Props = {
  setPaymentDetailsOpen: (value: boolean) => void;
  setError: (value: any) => void;
  modalRef?: any;
};

const MaskedInput = IMaskMixin(
  ({
    inputRef,
    ...props
  }: {
    inputRef: RefObject<HTMLInputElement>;
    [key: string]: any;
  }) => <Input {...props} ref={inputRef} />,
);

type StateDropdownOption = {
  name: string;
  state: string;
};
export const getFirstName = (fullName: string | undefined | null) => {
  if (!fullName) return ''; // Handle empty or undefined input
  return fullName.split(' ')[0];
};

export const getAccordionDisplayName = (
  queryParams: URLSearchParams,
  createAccountDisabled: boolean,
  getUserName: string,
) => {
  const searchParam = queryParams.get('fullName');
  let createAccountSection;
  try {
    const signup = sessionStorage.getItem('signup');
    const parsedSignup = JSON.parse(signup || '{}');
    createAccountSection = parsedSignup.createAccountSection;
  } catch (error) {
    createAccountSection = null;
  }

  // 1. Use query params
  if (searchParam) return `Hi, ${getFirstName(searchParam)}!`;

  // 2. If account was created, check sessionStorage
  if (createAccountDisabled && createAccountSection?.fullName)
    return `Hi, ${getFirstName(createAccountSection?.fullName)}!`;

  // 3. If account was created but sessionStorage is empty use GET response
  if (createAccountDisabled && getUserName)
    return `Hi, ${getFirstName(getUserName)}!`;

  return '';
};

const getStateName = (stateInfo: string | StateDropdownOption) => {
  if (typeof stateInfo === 'string') {
    const stateGeo = stateGeos.find(geos => geos.state === stateInfo);
    return stateGeo?.name || '';
  }
  return stateInfo?.name || '';
};

const stateFilter = (state: any) => {
  return (
    state?.item?.name
      ?.toLowerCase()
      .includes(state?.inputValue?.toLowerCase()) ||
    state?.item?.state?.toLowerCase().includes(state?.inputValue?.toLowerCase())
  );
};

const CreateAccountSection = ({
  setPaymentDetailsOpen,
  setError,
  modalRef,
}: Props) => {
  const { data: user } = useGetUser();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { t } = useTranslation('signup');
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const currentPage = window.location.pathname;
  const disableRecaptcha =
    !!localStorage.getItem('accessToken') || currentPage === '/signup/payment';

  const { mutate: createAccount } = useSubmitWithRecaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const [disableFields, setDisableFields] = useState(
    !!localStorage.getItem('accessToken'),
  );
  const [isAccountCreationSuccess, setIsAccountCreationSuccess] =
    useState(false);
  const trackEvent = useTrackEvent();
  const [isAccordionOpen, setAccordionOpen] = useState(!disableFields);

  const displayName = getAccordionDisplayName(
    searchParams,
    disableFields,
    user?.full_name,
  );

  const initialValues = useInitialValues({
    email: '',
    fullName: '',
    password: '',
    phone: '',
    company: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
    taxId: '',
    taxExempt: null,
    multipleLocations: null,
    purpose: '',
    complianceState: '',
    complianceCity: '',
  });

  const requiredKeys = [
    'email',
    'fullName',
    'password',
    'company',
    'street',
    'city',
    'state',
    'zipcode',
    'taxId',
    'taxExempt',
    'multipleLocations',
    'purpose',
  ];

  const onSubmit = async (data: any) => {
    setError(null);
    setIsLoading(true);
    let recaptchaCode: string | null | undefined;
    try {
      recaptchaCode = await recaptchaRef.current?.executeAsync();
    } catch (error) {
      setIsLoading(false);
      setError(error);
    } finally {
      recaptchaRef.current?.reset();
    }

    createAccount(
      { formData: data, recaptchaCode },
      {
        onSuccess: () => {
          setDisableFields(true);
          setPaymentDetailsOpen(true);
          setIsLoading(false);
          setIsAccountCreationSuccess(true);
        },
        onError: (error: any) => {
          setError({ error, source: 'create-account' });
          setIsLoading(false);
        },
      },
    );
  };

  const trackSaveClick = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_SIGNUP_PAGE_NEXT_CLICKED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { handleChange, values, errors, setFieldValue, setErrors } = formik;

  useEffect(() => {
    if (user && disableFields) {
      formik.setFieldValue('email', user.email);
      formik.setFieldValue('fullName', user.full_name);
      formik.setFieldValue('company', user.account.company.name);
      formik.setFieldValue('street', user.account.company.street);
      formik.setFieldValue('state', user.account.company.state);
      formik.setFieldValue('city', user.account.company.city);
      formik.setFieldValue('zipcode', user.account.company.zipcode);
      formik.setFieldValue('purpose', 'employment');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, disableFields]);

  useEffect(() => {
    if (errors) {
      modalRef?.current?.scrollIntoView();
    }
  }, [errors, formik.errors, modalRef]);

  useEffect(() => {
    const valuesCopy = { ...values };
    delete valuesCopy.password;
    updateSessionStorage('createAccountSection', valuesCopy);
  }, [values]);

  useEffect(() => {
    if (values.multipleLocations) {
      setFieldValue('complianceCity', '');
      setFieldValue('complianceState', '');
    }
  }, [values.multipleLocations, setFieldValue]);

  // TODO: Will be used in the future
  const handlePurposeChange = useCallback(() => {
    if (values.purpose === 'employment') {
      setFieldValue('purpose', undefined);
    } else setFieldValue('purpose', 'employment', false);
  }, [setFieldValue, values.purpose]);

  const handleStateChange = useCallback(
    e => {
      setFieldValue('state', e?.state || '');
    },
    [setFieldValue],
  );

  const handleTaxIdPaste = useCallback(
    e => {
      e.preventDefault();
      const pastedValue = e?.clipboardData?.getData('Text');
      const validFormatRegex = /^(\d{2}-?\d{7})$/;
      const validFormatting = validFormatRegex.test(pastedValue);

      if (!validFormatting) {
        setErrors({ taxId: 'Tax ID / EIN should be formatted XX-XXXXXXX' });
      } else {
        setErrors({ taxId: '' });
        setFieldValue('taxId', pastedValue);
      }
    },
    [setErrors, setFieldValue],
  );

  useEffect(() => {
    if (!disableFields) {
      trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PAGE_VIEWED, {
        'Page Type': 'checkr',
        'Self Serve Signup Version': 'FOX Signup v2',
      });
    }
  }, [disableFields, trackEvent]);

  useEffect(() => {
    if (isAccountCreationSuccess) {
      trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PAGE_COMPLETED, {
        'Page Type': 'checkr',
        'Self Serve Signup Version': 'FOX Signup v2',
      });
    }
  }, [isAccountCreationSuccess, trackEvent]);

  const handleAccordionHeadingClick = useCallback(() => {
    if (!isAccordionOpen) {
      trackEvent(FOX_SIGNUP_EVENT_NAMES.FOX_SIGNUP_ACCORDION_EXPANDED, {
        Accordion: 'Create Account',
      });
    }

    setAccordionOpen(!isAccordionOpen);
  }, [isAccordionOpen, trackEvent]);

  const validateRequiredFieldValuesSet = useCallback(() => {
    // loop over all required keys and make sure the value for that required key is set?
    let disableSubmit = false;
    requiredKeys.forEach(key => {
      if (
        values[key] === undefined ||
        values[key] === null ||
        values[key] === ''
      ) {
        disableSubmit = true;
      }
    });

    return disableSubmit;
  }, [requiredKeys, values]);

  const submitDisabled = validateRequiredFieldValuesSet();

  return (
    <Accordion
      primaryText='Create account'
      secondaryText={displayName}
      status={getFormStatus(values, errors, disableFields)}
      open={!disableFields}
      onHeadingClick={handleAccordionHeadingClick}
    >
      <form onSubmit={formik.handleSubmit}>
        <Container>
          <Section>
            <FlexColumn>
              <h5 className='mb-0'>About you</h5>
            </FlexColumn>
            <FormSection>
              <FormRow>
                <Input
                  labelText='Full name'
                  size='sm'
                  name='fullName'
                  id='fullName'
                  value={values.fullName}
                  onChange={handleChange}
                  warn={!!errors.fullName}
                  warnText={errors.fullName}
                  data-testid='fullName-input'
                  disabled={disableFields}
                  autocomplete='name'
                />
              </FormRow>
              <FormRow>
                <Input
                  labelText='Email'
                  size='sm'
                  name='email'
                  id='email'
                  value={values.email}
                  onChange={handleChange}
                  warn={!!errors.email}
                  warnText={errors.email}
                  data-testid='email-input'
                  disabled={disableFields}
                  autocomplete='email'
                />
                <PasswordInput
                  id='password'
                  data-testid='password-input'
                  size='sm'
                  name='password'
                  onChange={handleChange}
                  value={values.password}
                  warn={!!errors.password}
                  warnText={errors.password}
                  disabled={disableFields}
                  placeholder='Create password'
                />
              </FormRow>
              <FormRow>
                <MaskedInput
                  labelText='Phone number'
                  mask='000-000-0000'
                  width='50%'
                  size='sm'
                  name='phone'
                  id='phone'
                  value={values.phone}
                  onChange={handleChange}
                  warn={!!errors.phone}
                  warnText={errors.phone}
                  data-testid='phone-input'
                  disabled={disableFields}
                  autocomplete='tel'
                />
              </FormRow>
            </FormSection>
          </Section>
          <Section>
            <FlexColumn>
              <h5 className='mb-0'>About your business</h5>
              <SectionDescription>
                More information helps us create and verify your account faster.
              </SectionDescription>
            </FlexColumn>
            <FormSection gap='24px'>
              <FormRow>
                <Input
                  labelText='Business name'
                  name='company'
                  id='company'
                  value={values.company}
                  onChange={handleChange}
                  data-testid='company-input'
                  disabled={disableFields}
                  placeholder="Dimitry's Donuts"
                  warn={!!errors.company}
                  warnText={errors.company}
                />
              </FormRow>
              <FormRow>
                <Input
                  labelText='Business address'
                  name='street'
                  id='street'
                  value={values.street}
                  onChange={handleChange}
                  placeholder='Street address'
                  warn={!!errors.street}
                  warnText={errors.street}
                  data-testid='street-input'
                  disabled={disableFields}
                  autocomplete='street-address'
                />
                <Input
                  labelText=''
                  name='street2'
                  id='street2'
                  value={values.street2}
                  onChange={handleChange}
                  placeholder='Apt, suite, etc.'
                  data-testid='street2-input'
                  disabled={disableFields}
                  autocomplete='address-line2'
                />
              </FormRow>
              <FormRow>
                <Input
                  width='33%'
                  size='sm'
                  name='city'
                  id='city'
                  value={values.city}
                  onChange={handleChange}
                  placeholder='City'
                  warn={!!errors.city}
                  warnText={errors.city}
                  data-testid='city-input'
                  disabled={disableFields}
                  autocomplete='city'
                />
                <TypeaheadStyled
                  items={stateGeos}
                  id='state'
                  name='state'
                  onChange={handleStateChange}
                  itemToString={getStateName}
                  selectedItem={values.state}
                  warn={!!errors.state}
                  warnText={errors.state}
                  data-testid='state-input'
                  disabled={disableFields}
                  placeholder='State'
                  shouldFilterItem={stateFilter}
                />

                <MaskedInput
                  mask='00000'
                  width='33%'
                  size='sm'
                  name='zipcode'
                  id='zipcode'
                  value={values.zipcode}
                  onChange={handleChange}
                  placeholder='ZIP code'
                  warn={!!errors.zipcode}
                  warnText={errors.zipcode}
                  data-testid='zipcode-input'
                  disabled={disableFields}
                  autocomplete='postal-code'
                />
              </FormRow>
              <FormRow>
                <MaskedInput
                  mask='00-0000000'
                  width='50%'
                  labelText={
                    <>
                      <M.FormLabel>US Tax ID</M.FormLabel>
                      <M.Tooltip
                        label={t('pages.createAccountPage.taxIdTooltip')}
                      >
                        <TooltipIcon
                          icon='InformationFilled'
                          disabled
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                          }}
                        />
                      </M.Tooltip>
                    </>
                  }
                  size='sm'
                  name='taxId'
                  id='taxId'
                  value={values.taxId}
                  onChange={handleChange}
                  onPaste={handleTaxIdPaste}
                  placeholder='US Tax ID'
                  warn={!!errors.taxId}
                  warnText={errors.taxId}
                  data-testid='taxId-input'
                  disabled={disableFields}
                />
              </FormRow>
              <FormRow>
                <RadioButton>
                  <RadioButtonTitle>
                    Do you hire in multiple locations?
                  </RadioButtonTitle>
                  <ChoiceCardGroup
                    name='multipleLocations'
                    value={values.multipleLocations}
                    onChange={formik.setFieldValue}
                    label1='Yes'
                    label2='No'
                    disabled={disableFields}
                    error={errors.multipleLocations}
                  />
                </RadioButton>
              </FormRow>
              {values.multipleLocations === false && (
                <FormRow>
                  <ComplianceStateAndCitySelector
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                  />
                </FormRow>
              )}
              <FormRow>
                <RadioButton>
                  <RadioButtonTitle>
                    Is your business tax-exempt?
                  </RadioButtonTitle>
                  <ChoiceCardGroup
                    name='taxExempt'
                    value={values.taxExempt}
                    onChange={formik.setFieldValue}
                    label1='Yes'
                    label2='No'
                    disabled={disableFields}
                    error={errors.taxExempt}
                  />
                  {values.taxExempt && (
                    <RadioButtonSubtitle>
                      Send your state-issued sales tax exemption certificate to{' '}
                      <Bold>tax@checkr.com</Bold>
                    </RadioButtonSubtitle>
                  )}
                </RadioButton>
              </FormRow>
              <CheckboxRow>
                <CheckboxStyled
                  type='checkbox'
                  id='confirmation-of-purpose-checkbox'
                  data-testid='confirmation-of-purpose-checkbox'
                  name='purpose'
                  onChange={handlePurposeChange}
                  checked={values.purpose === 'employment'}
                  labelText={t(`pages.createAccountPage.confirmationOfPurpose`)}
                  warn={!!errors.purpose}
                  warnText={errors.purpose}
                  disabled={disableFields}
                />
              </CheckboxRow>
            </FormSection>
          </Section>
          <Section>
            <FlexColumn>
              <h5 className='mb-0'>Review your information</h5>
              <SectionDescription>
                Before you continue, confirm your information because you
                can&#39;t change it later.
              </SectionDescription>
            </FlexColumn>
            {disableFields ? (
              <span>Your account has been created.</span>
            ) : (
              <FormSection gap='24px'>
                {isLoading ? (
                  <M.LoadingInline description='Creating your account...' />
                ) : (
                  <M.Button
                    type='submit'
                    data-testid='create-account-save-continue-button'
                    onClick={trackSaveClick}
                    disabled={submitDisabled}
                  >
                    Save & continue
                  </M.Button>
                )}
                <CaptchaPolicy>
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a href='https://policies.google.com/privacy'>
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href='https://policies.google.com/terms'>
                    Terms of Service
                  </a>{' '}
                  apply.
                </CaptchaPolicy>
              </FormSection>
            )}
          </Section>
        </Container>
      </form>
      {!disableRecaptcha && (
        <HiddenReCAPTCHA
          ref={recaptchaRef}
          size='invisible'
          sitekey={RECAPTCHA_SITE_KEY || ''}
        />
      )}
    </Accordion>
  );
};

export default CreateAccountSection;
