// Please add all flag keys to this file so gitleaks doesn't flag it as a false positive.
// All exported keys will automatically be fetched via the initial batch request

// For future keys, please customize the key to something sementic in the flagr ui. You can edit it and hit save.
// This makes debugging the network request significantly easier when the flag is identifiable with a semantic key.
// Also, please provide a link as a comment to the flag for quick access, to further assist debugging.
// Note the customized semantic key, and flagr link, in this example.
// export const CUSTOMIZABLE_SEARCH_COLUMNS_FLAG_KEY = 'customizable_search_columns'; // https://flagr-ui.checkrhq.net/#/flags/617

// FLAGR flagKeys (strings)
/** Assess MVR */
export const ADDONS_AND_PACKAGE_EDITOR_SHOW_DRUG_SCREENING_SECTION_KEY = // https://flagr-ui.checkrhq.net/#/flags/757
  'kxevtjv3iieorgsyh';
export const ASSESS_RULE_API = 'ko9f3y36us3giobpu'; // https://flagr-ui.checkrhq.net/#/flags/876
export const AUTO_SHOW_ADDONS_FLAG_KEY = 'ko4qvdqnvdbwh9ez5'; // https://flagr-ui.checkrhq.net/#/flags/681
export const ENABLE_CREATE_NEW_PACKAGE_FLOW_KEY = 'ka3h99dnmnjef9jmp'; // https://flagr-ui.checkrhq.net/#/flags/765
export const HIDE_CREATE_PACKAGE_SUCCESS_TOAST = 'k1ax2m6rt5zp4zyx8'; // https://flagr-ui.checkrhq.net/#/flags/799
export const PR_US_STATE_KEY = 'kegz2afgxq7e2rwr5'; // https://flagr-ui.checkrhq.net/#/flags/462
export const SEND_BULK_AA_POST_NOTICES_BUTTON_TRIGGER_FLAG_KEY = // https://flagr-ui.checkrhq.net/#/flags/410
  'kieewandqodfi7ik8';
export const SEND_BULK_AA_POST_NOTICES_FLAG_KEY = 'k9mczbxtnojqpehbo'; // https://flagr-ui.checkrhq.net/#/flags/401
export const WASHINGTON_CONSENT_FLAG_KEY = 'washington_consent_display'; // https://flagr-ui.checkrhq.net/#/flags/501
export const SUBSCRIPTIONS_FLAG_KEY = 'k6j2ifjvbvt5esfd1'; // https://flagr-ui.checkrhq.net/#/flags/508
export const CC_FLAG_KEY = 'ko6nodsgexig6ie17'; // https://flagr-ui.checkrhq.net/#/flags/471
export const CC_DOWNLOAD_BUTTON_KEY = 'knvyhtetjezk7rax2'; // https://flagr-ui.checkrhq.net/#/flags/645
export const MANAGE_CC_AND_SUBSCRIPTIONS_FLAG_KEY = 'k1nka1s29v8bfg7qi'; // https://flagr-ui.checkrhq.net/#/flags/954
export const CC_ENROLLMENT_VISIBILITY_KEY = 'k1x3khz1i7rukyomh'; // https://flagr-ui.checkrhq.net/#/flags/818

export const UPGRADE_PACKAGES_FLAG_KEY = 'kuba8475fodkswdzv'; // https://flagr-ui.checkrhq.net/#/flags/383
export const EXPERIMENTAL_SEARCH_FILTER_UI_FLAG_KEY = // https://flagr-ui.checkrhq.net/#/flags/603
  'experimental_search_filter_ui';
export const CUSTOMIZABLE_SEARCH_COLUMNS_FLAG_KEY = // https://flagr-ui.checkrhq.net/#/flags/617
  'customizable_search_columns';
export const FAIR_CHANCE_FLAGR_KEY = 'kre3umuucnd2nnmha'; // https://flagr-ui.checkrhq.net/#/flags/602
export const VERIFICATIONS_EXCEPTIONS = 'kc39cnmz27zdpfrfu'; // https://flagr-ui.checkrhq.net/#/flags/621
export const CANDIDATE_PAGE_ORDER_BACKGROUND_CHECK_BUTTON = 'kjchecbthry22281g'; // https://flagr-ui.checkrhq.net/#/flags/628
export const INVITE_PAGE_ADD_SCREENINGS = 'kzteqrrzg6vswbncg'; // https://flagr-ui.checkrhq.net/#/flags/641
export const FLAG_KEY_EDIT_PACKAGE_OUT_OF_COUNTRY_HISTORY = 'k59d8fzc6gzyhss3b'; // https://flagr-ui.checkrhq.net/#/flags/705
export const GROWTH_INSIGHTS_FLAG_KEY = 'k47o31vh49tjevi8j'; // https://flagr-ui.checkrhq.net/#/flags/634
export const SHOW_EDIT_BUTTON_FOR_PACKAGES = 'kko4cmirqurhx3npj'; // https://flagr-ui.checkrhq.net/#/flags/717
export const SHOW_DELETE_BUTTON_FOR_PACKAGES = 'k5v75r5kumyjt286m'; // https://flagr-ui.checkrhq.net/#/flags/730
export const USE_HTTPLOGR_FOR_DASHBOARD_LOG_LIST = 'kvfau3v7h7axv9wu9'; // https://flagr-ui.checkrhq.net/#/flags/742
export const USE_HTTPLOGR_FOR_DASHBOARD_LOG_DETAILS = 'k6jzu54hka9291x5s'; // https://flagr-ui.checkrhq.net/#/flags/743
export const ACCOUNT_DEAUTHORIZATION_KEY = 'k57gg6xzyqpc256no'; // https://flagr-ui.checkrhq.net/#/flags/813
export const SELF_SERVICE_REAUTHORIZATION_KEY = 'km6afhsoh8r3dzzrb'; // https://flagr-ui.checkrhq.net/#/flags/1020
export const AUTO_ADVERSE_ACTION_FLAG_KEY = 'ke2uomauqydqnfbn5'; // https://flagr-ui.checkrhq.net/#/flags/1079

export const COMMERCIAL_MVR_ADD_ON_FLAG_KEY = 'commercial_mvr_add_on'; // https://flagr-ui.checkrhq.net/#/flags/1057
export const ORDER_REDESIGN_KEY = 'order_flow_redesign'; // https://flagr-ui.checkrhq.net/#/flags/833
export const ORDER_ADDONS_FLOW = 'order_addons_flow'; // https://flagr-ui.checkrhq.net/#/flags/971
export const ORDER_ADDONS_STATE_COUNTY = 'order_addons_state_county'; // https://flagr-ui.checkrhq.net/#/flags/1043
export const I9_ORDERING = 'kf5wnumj3c213fma1'; // https://flagr-ui.checkrhq.net/#/flags/1071
export const RECOMMENDED_PACKAGES_FLAG = 'ko5u2mzse9c6cg9g9'; // https://flagr-ui.checkrhq.net/#/flags/996
export const DRUG_SCREENING = 'kaweats657kbyosgf'; // https://flagr-ui.checkrhq.net/#/flags/862
export const INTERNAL_BANNER_FLAG_KEY = 'internal_dashboard_banner'; // https://flagr-ui.checkrhq.net/#/flags/881
export const ORDER_QUEUING_SUPPORT = 'kxupahxqpigezy81w'; // https://flagr-ui.checkrhq.net/#/flags/887
export const REPORT_DATA_MASSAGING = 'enable_report_data_massaging'; // https://flagr-ui.checkrhq.net/#/flags/895
export const LIMIT_TAGS_SEARCH_FLAGR_KEY =
  'limited_candidate_search_tags_filter'; // https://flagr-ui.checkrhq.net/#/flags/963

// Candidate Search
export const CANDIDATE_SEARCH_FILTER_REPORTS = 'k19ft5bsfseq2isfg'; // https://flagr-ui.checkrhq.net/#/flags/1003

// Candidate Redesign
export const CANDIDATE_REDESIGN = 'dashboard_candidate_redesign'; // https://flagr-ui.checkrhq.net/#/flags/1142
export const CANDIDATE_REDESIGN_PHASE_THREE =
  'dashboard_candidate_redesign_phase_three'; // https://flagr-ui.checkrhq.net/#/flags/1182

// Case Management
export const CASE_MANAGEMENT_TASK_UI_UPDATES_FLAG_KEY = 'k7f311j8i8s7j3gj3'; // https://flagr-ui.checkrhq.net/#/flags/1191

// UX Hackathon flags
export const UX_HACKATHON_GENERAL_FLAG_KEY = 'kuanirziaywhu8y2d'; // https://flagr-ui.checkrhq.net/#/flags/696

export const EMAIL_SUPPRESSION_FLAG_KEY = 'kukx3dxg7ny9mjjpz'; // https://flagr-ui.checkrhq.net/#/flags/734

export const SHOW_HIDE_PACKAGES_MENU_FLAG_KEY = 'k12vabmprk9aq54uz'; // https://flagr-ui.checkrhq.net/#/flags/608
// Candidate Product - Connection Team
export const SHOW_COMMUNICATIONS_SETTINGS_KEY = 'ktxupcox46aqfynny'; // https://flagr-ui.checkrhq.net/#/flags/798

// Amplitude Flags
export const ORDER_BGC_AMPLITUDE_FLAG_KEY = 'ku5rzq1s6dpeurr16'; // https://flagr-ui.checkrhq.net/#/flags/822
export const ORDER_BACKGROUND_CHECK_V2_AMPLITUDE = 'k4y9kk4wrgtu95f2v'; // https://flagr-ui.checkrhq.net/#/flags/865
export const ASSESS_STANDARD_AMPLITUDE_FLAG_KEY = 'k1bjwepv7fbkj3ibw'; // https://flagr-ui.checkrhq.net/#/flags/844
export const BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY = 'kj9xhfsijedkt36r9'; // https://flagr-ui.checkrhq.net/#/flags/861
export const USE_GLOBAL_WATCHLIST_SEARCH_IN_ADDONS = 'kg1mvuc2syiac5a23'; // https://flagr-ui.checkrhq.net/#/flags/886
export const ORDER_QUEUING_SUPPORT_FLAG_KEY = 'kxupahxqpigezy81w'; // https://flagr-ui.checkrhq.net/#/flags/887
export const ENABLE_ARCHIVE_REPORTS = 'kuqunh6jwey75j76j'; // https://flagr-ui.checkrhq.net/#/flags/907

export const AUTOMATIC_REDIRECT = 'react_automatic_internal_redirect'; // https://flagr-ui.checkrhq.net/#/flags/915
export const SELF_DISCLOSURE_PDF_BEFORE_REPORT_COMPLETION_FLAG_KEY =
  'kmce6jhe72mfh5e5j'; // https://flagr-ui.checkrhq.net/#/flags/912
export const USER_PREFERENCES_IMPROVEMENTS_FLAG_KEY = 'karcoxhv55kecwufn'; // https://flagr-ui.checkrhq.net/#/flags/929
export const COMMERCIAL_MVR_ROLLOUT = 'commercial_mvr_rollout'; // https://flagr-ui.checkrhq.net/#/flags/917
export const MANUAL_BULK_UPLOAD = 'kiryfxk4jhax36xta'; // https://flagr-ui.checkrhq.net/#/flags/988
export const CANDIDATE_SMS_SETTING_MOVED = 'k2s2qi1bvmtur4g87'; // https://flagr-ui.checkrhq.net/#/flags/1014
export const ALIAS_AS_AN_ADDON = 'kbix3728qgpz6jig5'; // https://flagr-ui.checkrhq.net/#/flags/1021
export const NEW_SUMMARY_COMPONENT = 'kneht2uzgb5fypvys'; // https://flagr-ui.checkrhq.net/#/flags/1144

// Progressive report upgrades v2
export const PROGRESSIVE_V2_MANUAL_UPGRADE = 'enable_progressive_v2_settings'; // https://flagr-ui.checkrhq.net/#/flags/956

// Health Screening Flags
export const HEALTH_SCREENING_EXAM_REORDER_UI_COMPONENTS =
  'health_screening_exam_reorder_ui_components'; // https://flagr-ui.checkrhq.net/#/flags/1046
export const PARS_UI_COMPONENTS = 'kg1khudypvtntf84j'; // https://flagr-ui.checkrhq.net/#/flags/1215

export const SHOW_CONTINUOUS_CHECKS_TAB = 'show_continuous_checks_tab'; // https://flagr-ui.checkrhq.net/#/flags/1056

// Account Hierarchy Flags
export const ACCOUNT_HIERARCHY_UI_CSV_FLAG_KEY = 'account_hierarchy_ui_csv'; // https://flagr-ui.checkrhq.net/#/flags/1111

// Signup Flags
export const FIRST_ORDER_EXPERIENCE_SIGNUP = 'first_order_experience_signup'; // https://flagr-ui.checkrhq.net/#/flags/1135

// Landing Page/Home Flags
export const SHOW_LANDING_PAGE = 'dashboard_landing_page'; // https://flagr-ui.checkrhq.net/#/flags/1131

// Reports Page
export const AI_REPORT_SUMMARY = 'ai_report_summary'; // https://flagr-ui.checkrhq.net/#/flags/1198

// Monetization Flags
export const MANAGE_TAX_EXEMPTION_CERTIFICATES = 'khiknpt81i8kmuiru'; // https://flagr-ui.checkrhq.net/#/flags/1203

// Better Dashboard Order Experience Flags
export const BETTER_DASHBOARD_ORDER_EXPERIENCE =
  'better-dashboard-order-experience'; // https://flagr-ui.checkrhq.net/#/flags/1216
