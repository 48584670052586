import { createContext, useContext } from 'react';
import type { ContextType } from './BetterOrderExperience.types';

type UpdateInput = Partial<ContextType>;

export type BetterOrderExperienceContextType = ContextType & {
  update: (values: UpdateInput) => void;
};

export const getInitialValues = () => {
  const storedValue = localStorage.getItem('betterOrderExperience');
  if (storedValue) {
    const storedObject = JSON.parse(storedValue);
    return storedObject;
  }
  return null;
};

const initialValues = getInitialValues();

export const INITIAL_VALUES = {
  infoSource: initialValues?.infoSource ?? 'CANDIDATE',
  geo: initialValues?.geo ?? {},
  location: initialValues?.location ?? {},
  node: initialValues?.node ?? {},
  program: initialValues?.program ?? {},
  invites: initialValues?.invites ?? [],
  csv: initialValues?.csv ?? {},
  manualBulkUploadType: initialValues?.manualBulkUploadType ?? {},
  localeType: initialValues?.localeType ?? {},
  basePackage: initialValues?.basePackage ?? {},
  addedScreeningType: initialValues?.addedScreeningType ?? {},
  aliasAdded: initialValues?.aliasAdded ?? false,
  saveForNextTime: initialValues?.saveForNextTime ?? false,
  newPackageName: initialValues?.newPackageName ?? '',
  manualBulkUploadData: initialValues?.manualBulkUploadData ?? {},
};

const BetterOrderExperienceContext =
  createContext<BetterOrderExperienceContextType>({
    ...INITIAL_VALUES,
    update: () => {},
  });

export default BetterOrderExperienceContext;

export const useBetterOrderExperienceContext = () =>
  useContext(BetterOrderExperienceContext);
