/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import BetterOrderExperienceContext, {
  INITIAL_VALUES,
} from 'pages/BetterOrderExperience/context';
import { Footer, StepContainer } from '../ui';
import { InfoSource } from './GetStartedStep.types';
import InfoSourceRadios from './InfoSourceRadios';

const GetStartedStep: React.FC<{}> = () => {
  const { t } = useTranslation('better_order_experience');
  const [selectedInfoSource, setSelectedInfoSource] = useState<InfoSource>(
    INITIAL_VALUES.infoSource,
  );

  // TODO: Update disabled logic
  const disabled = false;
  const history = useHistory();

  const { contextId } = useContext(UIContext);
  const { update, infoSource } = useContext(BetterOrderExperienceContext);

  const handleContinue = useCallback(() => {
    const path = '/order-experience/select-your-package';

    update({ infoSource: selectedInfoSource });

    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [update, selectedInfoSource, contextId, history]);

  return (
    <StepContainer data-testid='get-started-step-container'>
      <h4>{t('candidateDetails.header')}</h4>
      <InfoSourceRadios
        infoSource={infoSource}
        setSelectedInfoSource={setSelectedInfoSource}
      />
      {selectedInfoSource === 'CANDIDATE' && (
        <div>Selected info source: CANDIDATE</div>
      )}
      {selectedInfoSource === 'MYSELF' && (
        <div>Selected info source: MYSELF</div>
      )}
      <Footer>
        <M.Button
          data-testid='get-started-continue'
          type='button'
          disabled={disabled}
          onClick={handleContinue}
        >
          Continue
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default GetStartedStep;
